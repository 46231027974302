import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-form';

import { TextField, Buttons } from 'components/Custom/FormElements/';
import { required } from 'config/InputErrors';
import CardBox from 'components/CardBox/';
import SettingServices from 'services/Settings';
import SettingsActions from 'store/reducers/Settings';

class Settings extends React.Component {
  state = { data: {} };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    const response = await SettingServices.getById( 1 );
    toggleLoading( false );
    if ( response.ok ) this.setState( { data: response.data } );
  };

  submitForm = async ( values ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const response = await SettingServices.updateSettings( values.id, values );
    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );

    return toggleAlert( 'dataSaved' );
  };

  render() {
    const { data } = this.state;

    return (
      <div className="app-wrapper">
        <CardBox heading="settings">
          <div>
            <Form
              values={data}
              onSubmit={this.submitForm}
              validate={values => ( { geofencing: required( values.geofencing ) } )}
            >
              {( { submitForm } ) => (
                <form onSubmit={submitForm} className="row">
                  <div className="col-sm-6">
                    <TextField
                      type="number"
                      field="geofencing"
                      label="geofencing"
                    />
                  </div>

                  <div className="col-md-12 text-right">
                    <Buttons cancelTo="/" />
                  </div>
                </form>
              )}
            </Form>
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( Settings );
