import api from '../config/Api';

const baseUrl = 'v1.0/groups';

export default {
  getAll( params ) {
    return api.get( baseUrl, params );
  },

  createGroup( data ) {
    return api.post( `${baseUrl}`, data );
  },

  getGroup( id ) {
    return api.get( `${baseUrl}/${id}` );
  },

  updateGroup( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },

  deleteGroup( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },
};
