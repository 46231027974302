import api from '../config/Api';

const baseUrl = 'v1.0/appversions';

export default {

  getVersions( params ) {
    return api.get( baseUrl, params );
  },
};
