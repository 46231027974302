import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@material-ui/core';
import { Form } from 'react-form';

import { TextField, Buttons } from 'components/Custom/FormElements';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';

const LovValuesEdit = ( { open, onClose, data, onSubmit } ) => (
  <Dialog onClose={onClose} TransitionComponent={Slide} open={open}>
    <Form
      values={data}
      onSubmit={onSubmit}
      validate={values => ( { lovValue: required( values.lovValue ) } )}
    >
      {( { submitForm } ) => (
        <form onSubmit={submitForm} className="dialog-form">
          <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
            {!data.id ? <IntlMessage id="newValue" />
              : (
                <div>
                  <IntlMessage id="value" />
                  {': '}
                  {data.id || ''}
                </div>
              )
      }
          </DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-sm-6">
                <TextField field="lovValue" label="value" />
              </div>

              <div className="col-sm-6">
                <TextField field="lovValue2" label="value2" />
              </div>

              <div className="col-sm-6">
                <TextField field="codExt" label="igeaId" />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="col-md-12 text-right">
              <Buttons onClickCancel={onClose} />
            </div>
          </DialogActions>
        </form>
      )}
    </Form>
  </Dialog>
);

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( LovValuesEdit );
