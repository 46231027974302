import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, DialogActions, Button } from '@material-ui/core';
import _ from 'lodash';
import matchSorter from 'match-sorter';

import { TextField } from 'components/Custom/FormElements/';
import Table from 'components/Custom/Table/Custom/';
import IntlMessage from 'util/IntlMessages';

class Detail extends Component {
  state = {
    data: {},
    lovValues: [],
    criteria: '',
  };
  lovValues = [];

  componentDidUpdate( prevProps ) {
    const { data } = this.props;
    if ( !_.isEqual( data.csLovValues, prevProps.data.csLovValues ) ) {
      this.lovValues = [...data.csLovValues];
    }
  }

  static getDerivedStateFromProps( nextProps, prevState ) {
    if ( !_.isEqual( prevState.data, nextProps.data ) ) {
      return { data: nextProps.data, lovValues: nextProps.data.csLovValues };
    }
    return null;
  }

  filterAll = ( val ) => {
    const result = matchSorter( this.lovValues, val, { keys: ['id', 'lovValue', 'lovValue2', 'codExt'] } );
    this.setState( { criteria: val, lovValues: result } );
  };

  render() {
    const { onClose, open } = this.props;
    const { data, lovValues, criteria } = this.state;

    return (
      <Dialog onClose={onClose} TransitionComponent={Slide} open={open} maxWidth="sm" fullWidth>
        <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
          <IntlMessage id="lov" />
          :
          {' '}
          {data.lovName || ''}
        </DialogTitle>
        <DialogContent>
          <div className="row pb-2">
            <div className="col-sm-6 offset-sm-6">
              <TextField
                marginContainer={false}
                value={criteria}
                onChange={this.filterAll}
                placeholder="search"
              />
            </div>
          </div>

          <Table
            rowsText="values"
            data={lovValues}
            columns={[
              { Header: 'ID', accessor: 'id' },
              { Header: <IntlMessage id="value" />, accessor: 'lovValue' },
              { Header: <IntlMessage id="value2" />, accessor: 'lovValue2' },
              { Header: <IntlMessage id="igeaId" />, accessor: 'codExt' },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <div className="col-sm-12 text-right">
            <Button key="cancel" color="primary" variant="contained" className="jr-btn" onClick={onClose}>
              <i className="fa fa-times" />
              <IntlMessage id="close" />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Detail;
