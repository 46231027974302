import React, { Component } from 'react';
import { connect } from 'react-redux';

import TableHeader from 'components/Custom/Table/Header/';
import Table from 'components/Custom/Table/Custom/';
import TableActions from 'components/Custom/Table/Actions/';
import CheckColumn from 'components/Custom/Table/CheckColumn/';
import IntlMessage from 'util/IntlMessages';
import ConfirmationModal from 'components/Custom/ConfirmationModal';
import EntityAttributeService from 'services/EntityAttribute';
import SettingsActions from 'store/reducers/Settings';
import LovServices from 'services/Lov';
import Detail from 'routes/Lovs/Dashboard/components/Detail';
import EntityAttributeEdit from '../EntityAttributeEdit';

class EntityAttributesList extends Component {
  state = {
    detailModal: false,
    alertModal: false,
    lovModal: false,
  };
  entityDetail = {};
  entitySelected = {};

  toggleLovModal = async ( entity ) => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;

    if ( entity.id ) {
      if ( !isLoading ) toggleLoading( true );
      const response = await LovServices.getLov( entity.id );
      toggleLoading( false );

      if ( response.ok ) {
        this.entityDetail = response.data || [];
        this.setState( prevState => ( { lovModal: !prevState.detailModal } ) );
      } else toggleAlert( response.errors );
    } else {
      this.setState( prevState => ( { lovModal: !prevState.lovModal } ) );
    }
  };

  toggleEditModal = ( entity ) => {
    if ( entity ) this.entitySelected = entity;
    else this.entitySelected = {};
    this.setState( prevState => ( { detailModal: !prevState.detailModal } ) );
  };

  toggleAlertModal = ( entity ) => {
    if ( entity ) this.entitySelected = entity;
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  deleteEntity = async () => {
    const { isLoading, toggleLoading, toggleAlert, getData } = this.props;
    if ( !isLoading ) toggleLoading( true );

    if ( this.entitySelected.id ) {
      const response = await EntityAttributeService.deleteEntityAttribute( this.entitySelected.id );
      toggleLoading( false );
      if ( response.ok ) {
        this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
        getData();
      } else toggleAlert( response.errors );
    }
  };

  reloadData = () => {
    const { getData } = this.props;
    this.toggleEditModal();
    getData();
  };

  render() {
    const { criteria, onFilter, data, entityId } = this.props;
    const { alertModal, detailModal, lovModal } = this.state;

    return (
      <div className="border-top">
        <div className="my-4">
          <TableHeader
            styleTitle
            title="attributes"
            criteria={criteria}
            onFilter={onFilter}
            newAction={() => this.toggleEditModal()}
          />
        </div>

        <Table
          rowsText="attributes"
          data={data}
          columns={[
            { Header: 'ID', accessor: 'id', maxWidth: 80 },
            { Header: <IntlMessage id="name" />, accessor: 'AttributeName' },
            { Header: <IntlMessage id="type" />, accessor: 'AttributeType' },
            { Header: <IntlMessage id="igeaId" />, accessor: 'codExt' },
            { Header: <IntlMessage id="order" />, accessor: 'order' },
            {
              Header: <IntlMessage id="mandatory" />,
              accessor: 'isMandatory',
              className: 'justify-content-center',
              Cell: CheckColumn,
            },
            {
              Header: <IntlMessage id="meterRequired" />,
              accessor: 'meterRequired',
              className: 'justify-content-center',
              Cell: CheckColumn,
            },
            {
              Header: <IntlMessage id="requiredJob" />,
              accessor: 'jobRequired',
              className: 'justify-content-center',
              Cell: CheckColumn,
            },
            {
              Header: <IntlMessage id="lov" />,
              accessor: 'lov.lovName',
              Cell: props => (
                <button
                  className="m-0 p-0 bg-transparent border-0 text-primary"
                  type="button"
                  onClick={() => this.toggleLovModal( props.original.lov )}
                >
                  {props.value}
                </button>
              ),
            },
            {
              maxWidth: 120,
              className: 'justify-content-center',
              sortable: false,
              Cell: props => (
                <TableActions
                  onEdit={() => this.toggleEditModal( props.original )}
                  onDelete={() => this.toggleAlertModal( props.original )}
                />
              ),
            },
          ]}
        />

        <ConfirmationModal
          open={alertModal}
          translateValues={{
            name: this.entitySelected.AttributeName,
            type: 'attributes',
          }}
          onClose={() => this.toggleAlertModal()}
          onSubmit={this.deleteEntity}
        />

        <EntityAttributeEdit
          open={detailModal}
          onClose={() => this.toggleEditModal()}
          reloadData={this.reloadData}
          entityId={entityId}
          data={this.entitySelected}
        />

        <Detail
          open={lovModal}
          onClose={this.toggleLovModal}
          data={this.entityDetail}
        />
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( EntityAttributesList );
