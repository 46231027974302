import api from '../config/Api';

const baseUrl = 'v1.0/csentityobjects';

export default {
  getEntityObjects( params ) {
    return api.get( baseUrl, params );
  },

  createEntityObject( data ) {
    return api.post( `${baseUrl}`, data );
  },

  getEntityObject( id ) {
    return api.get( `${baseUrl}/${id}` );
  },

  updateEntityObject( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },

  deleteEntityObject( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },
};
