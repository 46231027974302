import React from 'react';
import _ from 'lodash';

import IntlMessage from 'util/IntlMessages';

const WorkDetail = ( { data, responsible, group } ) => (
  <div className="row mb-4">
    <div className="col-sm-4">
      <p className="mb-1">
        <strong><IntlMessage id="type" /></strong>
        {': '}
        {data.work_type ? data.work_type : '---'}
      </p>
      <p className="mb-1">
        <strong><IntlMessage id="user" /></strong>
        {': '}
        {data.user_name ? data.user_name : '---'}
      </p>
      <p className="mb-1">
        <strong><IntlMessage id="downloadUser" /></strong>
        {': '}
        {data.user_download ? data.user_download.name : '---'}
      </p>
    </div>
    <div className="col-sm-4">
      <p className="mb-1">
        <strong><IntlMessage id="status" /></strong>
        {': '}
        {data.work_status ? data.work_status : '---'}
      </p>
      <p className="mb-1">
        <strong><IntlMessage id="roles" /></strong>
        {': '}
        {!_.isEmpty( data.user_roles ) ? data.user_roles.map( rol => rol ).join( ', ' ) : '---'}
      </p>
    </div>
    <div className="col-sm-4">
      <p className="mb-1">
        <strong><IntlMessage id="responsibleUser" /></strong>
        {': '}
        {responsible ? `${responsible.name || ''} ${responsible.lastname || ''}` : '---'}
      </p>
      <p className="mb-1">
        <strong><IntlMessage id="responsibleGroup" /></strong>
        {': '}
        {group ? group.name : '---'}
      </p>
    </div>
  </div>
);

export default WorkDetail;
