import _ from 'lodash';

const email = val => ( val && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( val ) ? null : 'fieldError.email' );

const equalTo = ( fieldFromValue, fieldToValue ) => ( fieldFromValue === fieldToValue ? null : 'fieldError.equalTo' );

const minLength = ( val, length ) => ( val && val.length >= length ? null : `${length} or more characters is required` );

const required = ( val ) => {
  if ( _.isBoolean( val ) ) {
    return val ? null : 'fieldError.required';
  }
  return val && val.toString().length ? null : 'fieldError.required';
};

const oneRequired = ( valArray ) => {
  const missing = _.filter( valArray, ( val ) => {
    if ( _.isBoolean( val ) ) {
      if ( val ) return val;
    }
    if ( ( val && val.toString().length ) ) return val;
  } );
  return _.isEmpty( missing ) ? 'fieldError.oneRequired' : null;
};

const passwordValidation = val => ( val.match( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/ ) ? null
  : 'Password should contain at least one number, one lowercase character and one uppercase character.' );

const validUrl = ( val ) => {
  if ( val ) {
    return val.match( /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ ) ? null : 'This is not a valid url';
  }
  return null;
};

const validZip = val => ( val && /^\d{4,5}(?:-\d{4})?$/.test( val ) ? null : 'This is not a valid zip' );

export {
  email,
  equalTo,
  minLength,
  required,
  passwordValidation,
  validUrl,
  validZip,
  oneRequired,
};
