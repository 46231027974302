import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import async from 'async';

import Confirmation from 'components/Custom/ConfirmationModal';
import Table from 'components/Custom/Table/AsyncTable/';
import TableHeader from 'components/Custom/Table/Header/';
import CardBox from 'components/CardBox/';
import UserServices from 'services/User';
import SearchParams from 'util/SearchParams';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';
import Detail from './components/Detail';

class Dashboard extends React.Component {
  state = {
    data: [],
    search: {},
    criteria: null,
    detailModal: false,
    alertModal: false,
  };

  entitySelected = {};

  getData = async ( params, loading = true ) => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;

    if ( loading && !isLoading ) toggleLoading( true );

    async.parallel( {
      count: this.getCount,
      data: this.getUsers.bind( this, params ),
    }, ( error, result ) => {
      toggleLoading( false );

      if ( error ) return toggleAlert( error );

      this.setState( {
        data: result.data || [],
        pages: Math.ceil( result.count / params.limit ),
        total: result.count,
      } );
    } );
  };

  getCount = async ( cb ) => {
    const response = await UserServices.getUsers( { count: 1 } );
    return cb( response.errors, response.data );
  };

  getUsers = async ( params, cb ) => {
    const { search, criteria } = this.state;

    params = SearchParams.getSearchParams( { ...search, criteria }, params );
    params = { ...params, offset: params.offset ? params.offset - 1 : params.offset };
    this.params = _.cloneDeep( params );

    const response = await UserServices.getUsers( params );
    return cb( response.errors, response.data );
  };

  filterData = ( val ) => {
    if ( val ) {
      this.setState( { criteria: val }, () => this.getData( this.params, false ) );
    } else {
      this.setState( { criteria: null }, () => this.getData( this.params, false ) );
    }
  };

  toggleModal = ( entity ) => {
    this.entitySelected = entity;
    this.setState( prevState => ( { detailModal: !prevState.detailModal } ) );
  };

  toggleAlertModal = ( entity ) => {
    if ( entity ) this.entitySelected = entity;
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  toggleActive = async () => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;
    if ( !isLoading ) toggleLoading( true );

    const response = await UserServices.partialUpdateUser( this.entitySelected.id,
      { enabled: !this.entitySelected.enabled } );
    toggleLoading( false );
    if ( response.ok ) {
      this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
      this.getData( this.params );
    } else toggleAlert( response.errors );
  };

  render() {
    const { alertModal, detailModal, criteria, ...rest } = this.state;

    return (
      <div>
        <CardBox heading={(
          <TableHeader
            title="users"
            criteria={criteria}
            onFilter={this.filterData}
            linkNew="/users/new"
          />
        )}
        >
          <div>
            <Table
              onFetchData={this.getData}
              rowsText="users"
              {...rest}
              columns={[
                {
                  Header: <IntlMessage id="name" />,
                  accessor: 'name',
                  Cell: props => (
                    <button
                      className="m-0 p-0 bg-transparent border-0 text-primary text-justify"
                      type="button"
                      onClick={() => this.toggleModal( props.original )}
                    >
                      {props.original.name || ''}
                      {' '}
                      {props.original.lastname || ''}
                    </button>
                  ),
                },
                { Header: <IntlMessage id="email" />, accessor: 'email' },
                {
                  Header: <IntlMessage id="active" />,
                  accessor: 'enabled',
                  className: 'justify-content-center',
                  Cell: props => ( props.value
                    ? (
                      <button
                        className="m-0 p-0 bg-transparent border-0 text-success text-justify"
                        type="button"
                        onClick={() => this.toggleAlertModal( props.original )}
                      >
                        <i className="fa fa-check" />
                      </button>
                    )
                    : (
                      <button
                        className="m-0 p-0 bg-transparent border-0 text-danger text-justify"
                        type="button"
                        onClick={() => this.toggleAlertModal( props.original )}
                      >
                        <i className="fa fa-times" />
                      </button>
                    ) ),
                },
                {
                  maxWidth: 120,
                  className: 'justify-content-center',
                  sortable: false,
                  Cell: props => (
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className="jr-btn jr-btn-sm text-white"
                        component={Link}
                        to={`/users/edit/${props.original.id}`}
                      >
                        <i className="fa fa-edit" />
                      </Button>
                      <Button
                        variant="contained"
                        className="jr-btn jr-btn-sm text-white bg-warning"
                        component={Link}
                        to={`/users/reset-password/${props.original.id}`}
                      >
                        <i className="fa fa-key" />
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </CardBox>
        <Confirmation
          open={alertModal}
          title="userChangeConfirmation"
          confirmBtnText="userChangeConfirmButton"
          message="userChangeMessage"
          translateMessageValues={{
            option: this.entitySelected && this.entitySelected.enabled
              ? <IntlMessage id="disable" /> : <IntlMessage id="enable" />,
            type: <IntlMessage id="user" />,
            name: this.entitySelected ? this.entitySelected.name : '',
          }}
          onClose={this.toggleAlertModal}
          onSubmit={this.toggleActive}
        />
        <Detail
          open={detailModal}
          onClose={this.toggleModal}
          data={this.entitySelected}
        />
      </div>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Dashboard );
