import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@material-ui/core';
import { Form } from 'react-form';

import { TextField, Select, Checkbox, Buttons } from 'components/Custom/FormElements';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';
import LovService from 'services/Lov';
import EntityAttributeService from 'services/EntityAttribute';

class EntityAttributeEdit extends Component {
  state = { lovs: [] };

  componentDidMount() {
    this.getLovs();
  }

  getLovs = async () => {
    const response = await LovService.getLovs( { 'filters[lovInUse]': 1, limit: -1 } );
    if ( response.ok ) {
      this.setState( { lovs: response.data.data } );
    }
  };

  submitForm = async ( values ) => {
    const { toggleAlert, toggleLoading, data, entityId, reloadData } = this.props;
    toggleLoading( true );

    const response = data.id
      ? await EntityAttributeService.updateEntityAttribute( data.id, {
        ...values,
        lov: values.lov ? values.lov.toString() : null,
        csEntityObject: entityId,
      } )
      : await EntityAttributeService.createEntityAttribute( {
        ...values,
        csEntityObject: entityId,
      } );

    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );
    reloadData();
  };

  render() {
    const { open, onClose, data } = this.props;
    const { lovs } = this.state;

    return (
      <Dialog onClose={onClose} TransitionComponent={Slide} open={open}>
        <Form
          values={data}
          onSubmit={this.submitForm}
          validate={values => ( {
            AttributeName: required( values.AttributeName ),
            AttributeType: required( values.AttributeType ),
            order: required( values.order ),
          } )}
        >
          {( { submitForm } ) => (
            <form onSubmit={submitForm} className="dialog-form">
              <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
                {!data.id ? <IntlMessage id="newAttribute" />
                  : (
                    <div>
                      <IntlMessage id="attribute" />
                      {': '}
                      {data.AttributeName || ''}
                    </div>
                  )
                }
              </DialogTitle>
              <DialogContent className="pb-0">
                <div className="row">
                  <div className="col-sm-6">
                    <TextField field="AttributeName" label="name" />
                  </div>
                  <div className="col-sm-6">
                    <Select
                      field="AttributeType"
                      label="type"
                      labelKey="name"
                      options={[
                        { id: 'A30', name: 'A30' },
                        { id: 'A10', name: 'A10' },
                        { id: 'A20', name: 'A20' },
                        { id: 'INT', name: 'INT' },
                      ]}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Select
                      field="lov"
                      label="lov"
                      labelKey="lovName"
                      options={lovs}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField field="codExt" label="igeaId" />
                  </div>
                  <div className="col-sm-6">
                    <TextField field="order" label="order" type="number" />
                  </div>
                  <div className="col-sm-6">
                    <Checkbox field="meterRequired" optionValue="meterRequired" />
                  </div>
                  <div className="col-sm-3">
                    <Checkbox field="isMandatory" optionValue="mandatory" />
                  </div>
                  <div className="col-sm-3">
                    <Checkbox field="taskRequired" optionValue="requiredTask" />
                  </div>
                  <div className="col-sm-3">
                    <Checkbox field="jobRequired" optionValue="requiredJob" />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="col-sm-12 text-right">
                  <Buttons onClickCancel={onClose} />
                </div>
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( EntityAttributeEdit );
