import React from 'react';
import IntlMessages from 'util/IntlMessages';
import UserActions from "store/reducers/User";
import { connect } from "react-redux";

class UserInfoPopup extends React.Component {
  render() {
    const { name, lastName } = this.props;
    return (
      <div>
        <div className="user-profile">
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">{name}{' '}{lastName}</h4>
          </div>
        </div>
        <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>
          <IntlMessages id="menuProfile"/>
        </span>
        <span className="jr-link dropdown-item text-muted" onClick={this.props.logoutUser}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="menuLogout"/>
        </span>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( {
  name: user.name,
  lastName: user.lastName,
} );

const mapDispatchToProps = ( {
  logoutUser: UserActions.logoutUser,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( UserInfoPopup );


