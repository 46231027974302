import React from 'react';
import { Button } from '@material-ui/core';

import IntlMessage from 'util/IntlMessages';

const WorkHeader = ( { data, onEdit } ) => (
  <div className="row">
    <div className="col-sm-6">
      <IntlMessage id="workDetail" values={{ name: data.work_name }} />
    </div>
    <div className="col-sm-6 text-md-right">
      <Button
        variant="contained"
        className="jr-btn text-white"
        color="primary"
        onClick={onEdit}
      >
        <i className="fa fa-edit" />
        <IntlMessage id="edit" />
      </Button>
      <Button
        variant="contained"
        className="jr-btn text-white"
        color="primary"
      >
        <i className="fa fa-file-alt" />
        <IntlMessage id="export" />
      </Button>
    </div>
  </div>
);

export default WorkHeader;
