import React from 'react';
import classname from 'classnames';
import { Button } from '@material-ui/core';
import moment from 'moment';

import CardBox from 'components/CardBox/';
import Timeline from 'components/Custom/Timeline';
import IntlMessage from 'util/IntlMessages';

const WorkTimeline = ( { data, onClickItem, activeIndex, onDelete, selectedItem } ) => {
  const cardClass = activeIndex !== -1 ? 'fadeIn d-block' : 'fadeOut d-none';
  return (
    <div className="col-md-12">
      <div className="pt-3 pb-2 border mb-4">
        <Timeline
          items={data}
          labelKey="date"
          onClickItem={onClickItem}
          activeIndex={activeIndex}
        />
      </div>
      <CardBox
        styleName={classname( 'animated delay-2s', cardClass )}
        cardStyle="p-3"
        heading={(
          <div className="row align-items-center">
            <div className="col-sm-6">
              <IntlMessage id="workDetail" values={{ name: selectedItem.id || '' }} />
            </div>
            <div className="col-sm-6 text-md-right">
              <Button
                variant="contained"
                className="jr-btn jr-btn-sm text-white bg-danger"
                onClick={onDelete}
              >
                <i className="fa fa-trash" />
              </Button>
            </div>
          </div>
        )}
      >
        <div className="row">
          <div className="col-sm-4">
            <p className="mb-1">
              <strong><IntlMessage id="name" /></strong>
              {': '}
              {selectedItem.work_name ? selectedItem.work_name : '---'}
            </p>
            <p className="mb-1">
              <strong><IntlMessage id="updatedAt" /></strong>
              {': '}
              {selectedItem.update_date
                ? moment( new Date( selectedItem.update_date ) ).format( 'DD/MM/YYYY hh:mm A' )
                : '---'}
            </p>
            <p className="mb-1">
              <strong><IntlMessage id="user" /></strong>
              {': '}
              {selectedItem.user_name ? selectedItem.user_name : '---'}
            </p>
          </div>
          <div className="col-sm-4">
            <p className="mb-1">
              <strong><IntlMessage id="status" /></strong>
              {': '}
              {selectedItem.work_status ? <IntlMessage id={selectedItem.work_status} /> : '---'}
            </p>
            <p className="mb-1">
              <strong><IntlMessage id="device" /></strong>
              {': '}
              {selectedItem.device ? selectedItem.device : '---'}
            </p>
          </div>
          <div className="col-sm-4">
            <p className="mb-1">
              <strong><IntlMessage id="acceptUser" /></strong>
              {': '}
              {selectedItem.user_accept ? selectedItem.user_accept.name : '---'}
            </p>
            <p className="mb-1">
              <strong><IntlMessage id="rejectUser" /></strong>
              {': '}
              {selectedItem.user_reject ? selectedItem.user_reject.name : '---'}
            </p>
          </div>
        </div>
      </CardBox>
    </div>
  );
};

export default WorkTimeline;
