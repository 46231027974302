import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-form';

import ContainerHeader from 'components/ContainerHeader';
import { TextField, Buttons } from 'components/Custom/FormElements';
import { required } from 'config/InputErrors';
import CardBox from 'components/CardBox/';
import UserServices from 'services/User';
import SettingsActions from 'store/reducers/Settings';

class ResetPassword extends Component {
  state = { data: {} };
  entityId = null;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params ? match.params.id : null;
    if ( this.entityId ) this.getData();
  }

  getData = async () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    const response = await UserServices.getUser( this.entityId );
    toggleLoading( false );
    if ( response.ok ) this.setState( { data: response.data } );
    else toggleAlert( response.errors );
  };

  submitForm = async ( values ) => {
    const { toggleLoading, toggleAlert, history } = this.props;
    toggleLoading( true );

    if ( this.entityId ) {
      const response = await UserServices.partialUpdateUser( this.entityId,
        { password: values.password } );
      toggleLoading( false );
      if ( response.ok ) {
        toggleAlert( 'dataSaved' );
        return history.push( '/users' );
      }
      toggleAlert( response.errors );
    }
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <ContainerHeader />

        <CardBox
          heading="passwordReset"
          headingTranslateValues={{ name: data.name }}
        >
          <div>
            <Form
              onSubmit={this.submitForm}
              validate={values => ( {
                password: required( values.password ),
                passwordConfirmation: required( values.passwordConfirmation )
                  || ( values.password !== values.passwordConfirmation
                    ? 'passwordMatch' : null ),
              } )}
            >
              {( { submitForm } ) => (
                <form onSubmit={submitForm} className="row">
                  <div className="row w-100 m-0">
                    <div className="col-sm-6">
                      <TextField field="password" type="password" label="password" />
                    </div>
                  </div>

                  <div className="row w-100 m-0">
                    <div className="col-sm-6">
                      <TextField
                        field="passwordConfirmation"
                        type="password"
                        label="passwordConfirmation"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 text-right">
                    <Buttons cancelTo="/users" />
                  </div>
                </form>
              )}
            </Form>
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( ResetPassword );
