import React from 'react';

import ContainerHeader from 'components/ContainerHeader';

const Entities = ( { match } ) => (
  <div className="app-wrapper">
    <ContainerHeader match={match} title="sidebarEntityObject" />
  </div>
);

export default Entities;
