import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@material-ui/core';
import { Form } from 'react-form';
import _ from 'lodash';

import { Select, Buttons } from 'components/Custom/FormElements';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';
import EntityObjectService from 'services/EntityObject';

class EntityChildrenEdit extends Component {
  state = { options: [] };

  componentDidMount() {
    this.getEntityObjects();
  }

  componentDidUpdate( props ) {
    const { data, open } = this.props;
    if ( !_.isEqual( data, props.data ) || ( open !== props.open && open ) ) {
      this.getEntityObjects();
    }
  }

  getEntityObjects = async () => {
    const { entityId, data } = this.props;
    const currentDependents = data.map( child => child.csChildEntityObject ) || [];
    const response = await EntityObjectService.getEntityObjects( { limit: -1 } );
    if ( response.ok ) {
      this.setState( {
        options: _.filter( _.differenceWith( response.data.data, currentDependents, _.isEqual ),
          item => item.id.toString() !== entityId ),
      } );
    }
  };

  render() {
    const { open, onClose, onSubmit } = this.props;
    const { options } = this.state;

    return (
      <Dialog
        onClose={onClose}
        TransitionComponent={Slide}
        open={open}
        fullWidth
      >
        <Form
          onSubmit={values => onSubmit( values )}
          validate={values => ( { dependent: required( values.dependent ) } )}
        >
          {( { submitForm } ) => (
            <form onSubmit={submitForm} className="dialog-form">
              <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
                <IntlMessage id="dependent" />
              </DialogTitle>
              <DialogContent>
                <div className="row">
                  <div className="col-sm-6 mb-5">
                    <Select
                      field="dependent"
                      label="entity-object"
                      labelKey="objectName"
                      options={options}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="col-sm-12 text-right">
                  <Buttons onClickCancel={onClose} />
                </div>
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( EntityChildrenEdit );
