import _ from 'lodash';

export default {

  getSearchParams( searchParams, _tableParams ) {
    const tableParams = _tableParams;
    _.forEach( searchParams, ( value, key ) => {
      if ( _.isArray( value ) ? value && value.length : value ) tableParams[`filters[${key}]`] = _.isArray( value ) ? value.join( ',' ) : value;
      else if ( _.get( tableParams, `filters[${key}]` ) ) delete tableParams[`filters[${key}]`];
    } );

    return tableParams;
  },
};
