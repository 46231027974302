import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide, Button } from '@material-ui/core';
import _ from 'lodash';

import IntlMessage from 'util/IntlMessages';

export default ( { open, data, onClose } ) => (
  <Dialog onClose={onClose} TransitionComponent={Slide} open={open}>
    <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
      <IntlMessage id="user" />
      {`: ${data.name || ''} ${data.lastname || ''}`}
    </DialogTitle>
    <DialogContent>
      <div className="row">
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="username" /></strong>
          {': '}
          {data.username || '---'}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="email" /></strong>
          {': '}
          {data.email || '---'}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="enabled" /></strong>
          {': '}
          {data.enabled
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="canDownload" /></strong>
          {': '}
          {data.download
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="group" /></strong>
          {': '}
          {data.user_group ? data.user_group.name || '---' : '---'}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="profiles" /></strong>
          {': '}
          {!_.isEmpty( data.roles ) ? (
            <ul>
              {data.roles.map( rol => ( <li className="list-unstyled" key={rol}>{rol}</li> ) )}
            </ul>
          )
            : '---'}
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <div className="col-md-12 text-right">
        <Button color="primary" variant="contained" className="jr-btn" onClick={onClose}>
          <i className="fa fa-times" />
          <IntlMessage id="close" />
        </Button>
      </div>
    </DialogActions>
  </Dialog>
);
