import api from '../config/Api';

const baseUrl = 'v1.0/cslovvalues';

export default {
  getLovValues( params ) {
    return api.get( baseUrl, params );
  },

  getLovValue( id ) {
    return api.get( `${baseUrl}/${id}` );
  },

  createLovValue( data ) {
    return api.post( baseUrl, data );
  },

  updateLovValue( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },

  deleteLovValue( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },
};
