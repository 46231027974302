import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";

import { TextField } from 'components/Custom/FormElements/';
import IntlMessage from 'util/IntlMessages';

export default ( { title, styleTitle, criteria, onFilter, linkNew, newAction } ) => (
  <div className="row align-items-center">
    <div className="col-md-4">
      {styleTitle ? <h4 className="m-0"><IntlMessage id={title} /></h4> :
      <IntlMessage id={title} />
      }
    </div>
    <div className="col-md-4">
      <TextField
        marginContainer={false}
        value={criteria}
        onChange={onFilter}
        placeholder="search"
      />
    </div>
    <div className="col-md-4 text-right">
      {!!linkNew &&
      <Button
        variant="contained"
        className="jr-btn text-white"
        color="primary"
        component={Link}
        to={linkNew}
      >
        <i className="fa fa-plus" />
        <IntlMessage id="create" />
      </Button>
      }
      {!!newAction &&
      <Button
        variant="contained"
        className="jr-btn text-white"
        color="primary"
        onClick={newAction}
      >
        <i className="fa fa-plus" />
        <IntlMessage id="create" />
      </Button>
      }
    </div>
  </div>
);
