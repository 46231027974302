import React from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';

import TablePagination from '../Pagination';
import IntlMessage from 'util/IntlMessages';

class AsyncTable extends React.Component {
  getData = ( params ) => {
    const { onFetchData } = this.props;
    const apiParams = {
      limit: params.pageSize,
      offset: ( params.page * params.pageSize ) + 1,
    };
    if ( params.sorted.length ) apiParams[`order_by[${params.sorted[0].id}]`] = params.sorted[0].desc ? 'DESC' : 'ASC';
    onFetchData( apiParams );
  };

  render() {
    const { className, showPageSizeOptions, rowsText, ...rest } = this.props;

    return (
      <ReactTable
        className={`app-table -striped -highlight ${className}`}
        defaultPageSize={10}
        manual
        minRows={rest.data.length}
        pageSizeOptions={[10, 20, 30]}
        resizable={false}
        showPaginationBottom={false}
        showPaginationTop
        getTheadFilterThProps={() => ( { style: { position: 'inherit', overflow: 'inherit' } } )}
        showPageSizeOptions={_.isUndefined( showPageSizeOptions ) ? true : showPageSizeOptions}
        PaginationComponent={props => <TablePagination {...props} />}
        rowsText={!!rowsText ? <IntlMessage id={rowsText}/>: ""}
        {...rest}
        onFetchData={this.getData}
      />
    );
  }
}

export default AsyncTable;
