import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

export default ( { items, valueKey, labelKey, activeIndex, onClickItem } ) => (
  <div className="horizontal-timeline">
    {_.map( items, ( item, index ) => (
      <div key={item[valueKey || 'id']} className={classnames( 'horizontal-timeline--item', {
        active: index === activeIndex
      } )}
           onClick={() => onClickItem ? onClickItem( item, index ) : {}}>
        <div className="horizontal-timeline--indicator"/>
        <p className="mb-0">{item[labelKey || 'label']}</p>
      </div>
    ) )}
  </div>
);
