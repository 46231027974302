import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'components/SideNav/index';
import Footer from 'components/Footer';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  HORIZONTAL_NAVIGATION,
} from 'store/constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import TopNav from 'components/TopNav';
import Error404 from 'components/Error404';
import Dashboard from './Dashboard';
import Entities from './Entities';
import Groups from './Groups';
import Lovs from './Lovs';
import LovMeters from './LovMeters';
import Reports from './Reports';
import Settings from './Settings';
import Users from './Users';
import Works from './Works';
import EntityObjects from './EntityObjects';

const App = ( { match, navCollapsed, navigationStyle, horizontalNavPosition } ) => {
  const drawerStyle = navCollapsed ? 'mini-drawer' : 'fixed-drawer';

  // set default height and overflow for iOS mobile Safari 10+ support.
  if ( isIOS && isMobile ) {
    document.body.classList.add( 'ios-mobile-view-height' );
  } else if ( document.body.classList.contains( 'ios-mobile-view-height' ) ) {
    document.body.classList.remove( 'ios-mobile-view-height' );
  }

  return (
    <div className={`app-container ${drawerStyle}`}>
      <div
        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}
      >
        {( navigationStyle === HORIZONTAL_NAVIGATION
          && horizontalNavPosition === ABOVE_THE_HEADER )
        && <TopNav styleName="app-top-header" />}
        <Header />
        {( navigationStyle === HORIZONTAL_NAVIGATION
          && horizontalNavPosition === BELOW_THE_HEADER )
        && <TopNav />}
      </div>

      <Sidebar />

      <div className="app-main-container">
        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            <Switch>
              <Route path={`${match.url}dashboard`} component={Dashboard} />
              <Route path={`${match.url}entities`} component={Entities} />
              <Route path={`${match.url}groups`} component={Groups} />
              <Route path={`${match.url}lov-meter`} component={LovMeters} />
              <Route path={`${match.url}lov`} component={Lovs} />
              <Route path={`${match.url}reports`} component={Reports} />
              <Route path={`${match.url}settings`} component={Settings} />
              <Route path={`${match.url}users`} component={Users} />
              <Route path={`${match.url}works`} component={Works} />
              <Route path={`${match.url}entity-object`} component={EntityObjects} />
              <Route component={Error404} />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = ( { settings } ) => {
  const { navCollapsed, navigationStyle, horizontalNavPosition } = settings;
  return { navCollapsed, navigationStyle, horizontalNavPosition };
};
export default withRouter( connect( mapStateToProps )( App ) );
