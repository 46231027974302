import React from 'react';
import { Field } from 'react-form';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import Translate from 'util/IntlMessages';

const TextFieldInput = ( {
  containerClass, field, fullWidth, onChange,
  onBlur, translateValues, intl,
  marginContainer = true,
  ...rest,
} ) => (
  field ? (
    <Field field={field} pure={false}>
      {( { error, setTouched, setValue, touched, value } ) => (
        <FormControl error={touched && !!error} className={classNames( 'mb-4 w-100', containerClass )}>
          <TextField
            InputLabelProps={{ className: 'label-shrink' }}
            {...rest}
            label={<Translate id={rest.label}/>}
            error={touched && !!error}
            value={value || ''}
            onChange={( e ) => {
              setValue( e.target.value );
              if( onChange ) onChange( e.target.value, e );
            }}
            onBlur={( e ) => {
              setTouched();
              if( onBlur ) onBlur( e );
            }}
          />
          {touched && error
          && <FormHelperText>{<Translate id={error} values={translateValues}/>}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  ) : (
    <FormControl className={classNames( 'w-100', containerClass, {
      'mb-4': marginContainer,
    } )}>
      <TextField
        InputLabelProps={{ className: 'label-shrink' }}
        {...rest}
        label={rest.label ? <Translate id={rest.label}/> : ''}
        placeholder={rest.placeholder ? intl.formatMessage( { id: rest.placeholder } ) : null}
        value={rest.value || ''}
        onChange={( e ) => {
          if( onChange ) onChange( e.target.value, e );
        }}
        onBlur={( e ) => {
          if( onBlur ) onBlur( e );
        }}
      />
    </FormControl>
  ) );

export default injectIntl( TextFieldInput );
