import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { TextField } from 'components/Custom/FormElements/';
import { Form } from 'react-form';

import IntlMessage from 'util/IntlMessages';
import { required } from 'config/InputErrors';

export default ( { translateValues, translateMessageValues, open, onSubmit, onClose, title, message, confirmBtnText, input } ) => {
  let comment = '';
  let submitFormFn = () => {};

  return (
    <SweetAlert
      show={open}
      warning
      showCancel
      confirmBtnText={confirmBtnText
        ? <IntlMessage id={confirmBtnText} /> : <IntlMessage id="tableDeleteConfirmButton" />}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="default"
      title={title ? <IntlMessage id={title} values={translateValues}/>
        : <IntlMessage id="tableDeleteConfirmation" values={translateValues} />}
      onConfirm={() => {
        if ( input ) {
          if ( comment ) onSubmit( comment );
          else submitFormFn();
        } else onSubmit();
      }}
      onCancel={onClose}
    >
      <div className="small">
        {message
          ? <IntlMessage id={message} values={translateMessageValues}/>
          : <IntlMessage id="tableDeleteMessage" values={translateMessageValues}/>
        }
      </div>
      {input
      && (
        <Form validate={values => ( { comment: required( values.comment ) } )}>
          {( { submitForm } ) => {
            submitFormFn = submitForm;
            return (
              <form onSubmit={submitForm}>
                <TextField
                  placeholder="EnterComment"
                  field="comment"
                  onChange={( val ) => {
                    comment = val;
                  }}
                />
              </form>
            );
          }}
        </Form>
      )}
    </SweetAlert>
  );
};
