import React from 'react';
import { Field } from 'react-form';
import { InputLabel, FormControl, FormHelperText, Input } from '@material-ui/core';
import _ from 'lodash';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import Translate from 'util/IntlMessages';
import SelectWrapper from './selectWrapper';

const SelectField = ( {
  containerClass, emptyValue = true, field, fullWidth = true, marginContainer = true,
  idKey, intl, label, labelKey, onChange, options, translateOptions = true, ...props
} ) => {
  options = emptyValue && !props.isMulti ? [{ id: null }, ...options] : options;
  return (
    field ? (
      <Field field={field} options={options}>
        {( { error, value, setValue, setTouched, touched, options } ) => {
          const id = idKey || 'id';
          const predefinedValue = value ?
            _.isObject( value ) ? props.isMulti ? _.isObject(value[0]) ? _.map( value, id ) : value : value[id] : value
            : ( props.isMulti ? [] : '' );
          return (
            <FormControl
              className={classNames( 'mb-4', containerClass, { 'w-100': fullWidth } )}
              error={touched && !!error}
            >
              {label && <InputLabel className="label-shrink">{<Translate id={label}/>}</InputLabel>}
              <Input
                inputComponent={SelectWrapper}
                onBlur={() => setTouched()}
                classes={{
                  input: 'select-input'
                }}
                inputProps={{
                  ...props,
                  className: { 'pb-0': value ? props.isMulti ? !!value.length : false : !!value },
                  placeholder: !!props.placeholder ? <Translate id={props.placeholder} /> : '',
                  error: touched && !!error,
                  value: predefinedValue,
                  onChange: ( val ) => {
                    if( val !== value ) {
                      setValue( props.isMulti ? _.map( val, 'value' ) : val.value );
                      if( onChange ) onChange( props.isMulti ? _.map( val, 'value' ) : val.value ? val.value : null );
                    }
                  },
                  options: _.map( options, option => ( {
                    value: option[idKey || 'id'],
                    label: translateOptions ? option[labelKey || 'name'] ? option[labelKey || 'name']
                      : option[labelKey || 'name']
                      : option[labelKey || 'name'],
                    icon: option.icon
                  } ) ),
                }}
              />
              {touched && error && <FormHelperText>{<Translate id={error}/>}</FormHelperText>}
            </FormControl>
          );
        }}
      </Field>
    ) : (
      <FormControl className={classNames( containerClass, {
        'w-100': fullWidth,
        'mb-4': marginContainer,
      } )}
      >
        {label && <InputLabel className="label-shrink">{<Translate id={label}/>}</InputLabel>}
        <Input
          inputComponent={SelectWrapper}
          classes={{
            input: 'select-input'
          }}
          inputProps={{
            ...props,
            className: 'pb-0',
            placeholder: !!props.placeholder ? <Translate id={props.placeholder} /> : '',
            value: props.value ? props.value : props.isMulti ? [] : '',
            onChange: ( val ) => {
              if( onChange ) onChange( props.isMulti ? _.map( val, 'value' ) : val.value ? val.value : null );
            },
            options: _.map( options, option => ( {
              value: option[idKey || 'id'],
              label: translateOptions ? option[labelKey || 'name'] ? intl.formatMessage( { id: option[labelKey || 'name'] } )
                : option[labelKey || 'name']
                : option[labelKey || 'name'],
            } ) ),
          }}
        />
      </FormControl>
    ) );
};

export default injectIntl( SelectField );
