import { create } from 'apisauce';
import _ from 'lodash';

const api = create( {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
} );

api.addRequestTransform( ( request ) => {
  request.url = `${process.env.REACT_APP_API_URL}${request.url}`;

  const accessToken = _.get( JSON.parse( localStorage.getItem( 'csw' ) ), 'user.access_token' );
  if ( accessToken ) request.headers.Authorization = `Bearer ${accessToken}`;
} );

api.addResponseTransform( ( response ) => {
  const { data, ok } = response;

  if ( !ok ) {
    let errors = '';

    if ( data ) {
      if ( data.error === 'invalid_grant' ) {
        window.location.replace( '/login' );
      }

      if ( data.error_description ) errors = data.error_description;
      else if ( data.errors ) {
        if ( _.isObject( data.errors ) && !_.isArray( data.errors ) ) {
          errors = [];
          _.map( data.errors, ( error ) => {
            if ( _.isString( error ) ) errors.push( error );
            else if ( _.isArray( errors ) ) errors.push( error.join( '\n' ) );
          } );
          errors = errors.join( '\n' );
        } else {
          ( { errors } = data );
        }
      } else if ( data.message ) errors = data.message;
    } else {
      errors = 'API ERROR';
    }

    response.errors = errors;
  }
} );

export default api;
