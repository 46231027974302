import React, { Component } from 'react';
import { Form } from 'react-form';
import { connect } from 'react-redux';

import ContainerHeader from 'components/ContainerHeader';
import GroupServices from 'services/Groups';
import { TextField, Buttons } from 'components/Custom/FormElements/';
import { required } from 'config/InputErrors';
import CardBox from 'components/CardBox/';
import SettingsActions from 'store/reducers/Settings';

class Edit extends Component {
  state = { data: {} };
  entityId = null;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;
    if ( this.entityId ) this.getData();
  }

  getData = async () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    const response = await GroupServices.getGroup( this.entityId );
    toggleLoading( false );
    if ( response.ok ) this.setState( { data: response.data } );
  };

  submitForm = async ( data ) => {
    const { history, toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    const response = this.entityId ? await GroupServices.updateGroup( this.entityId, data )
      : await GroupServices.createGroup( data );

    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );

    return history.push( '/groups' );
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        <ContainerHeader />

        <CardBox
          heading={this.entityId ? 'editGroup' : 'createGroup'}
          headingTranslateValues={{ name: data.name }}
          styleName="col-12 p-3"
        >
          <Form
            values={data}
            onSubmit={this.submitForm}
            validate={values => ( { name: required( values.name ) } )}
          >
            {( { submitForm } ) => (
              <form onSubmit={submitForm} className="row">
                <div className="col-sm-6">
                  <TextField field="name" label="name" />
                </div>

                <div className="col-md-12 text-right">
                  <Buttons cancelTo="/groups" />
                </div>
              </form>
            )}
          </Form>
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( Edit );
