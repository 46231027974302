import React from 'react';

import ContainerHeader from 'components/ContainerHeader';

const Reports = ( { match } ) => (
  <div className="app-wrapper">
    <ContainerHeader match={match} title="sidebarReports" />
  </div>
);

export default Reports;
