const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es'
  },
  {
    languageId: 'portuguese',
    locale: 'pt',
    name: 'Portuguese',
    icon: 'pt'
  }

];
export default languageData;
