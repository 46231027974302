import React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, DialogActions, Button } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

import IntlMessage from 'util/IntlMessages';

export default ( { onClose, open, data } ) => (
  <Dialog onClose={onClose} TransitionComponent={Slide} open={open} maxWidth="sm" fullWidth>
    <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
      <IntlMessage id="entity-object" />
      :
      {' - ID #'}
      {data.id || ''}
    </DialogTitle>
    <DialogContent>
      <div className="row">
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="name" /></strong>
          {': '}
          {data.objectName}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="updatedAt" /></strong>
          {': '}
          {data.updateDate ? moment( new Date( data.updateDate ) ).format( ' DD/MM/YYYY hh:mm A' ) : ''}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="type" /></strong>
          {': '}
          {data.objecType}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="topology" /></strong>
          {': '}
          {data.objecTopology}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="codExt" /></strong>
          {': '}
          {data.codExt ? data.codExt : '---'}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="isActive" /></strong>
          {': '}
          {data.objecActive === 1
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="firstLevel" /></strong>
          {': '}
          {data.firstLevel
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
        <div className="col-sm-12 mb-1">
          <strong><IntlMessage id="description" /></strong>
          {': '}
          {data.objecDescription ? data.objecDescription : '---'}
        </div>
        <div className="col-sm-12 mb-1">
          <strong><IntlMessage id="attributes" /></strong>
          {': '}
          {!_.isEmpty( data.csEntityAttributes )
            ? data.csEntityAttributes.map( attribute => attribute.AttributeName ).join( ', ' )
            : '---'}
        </div>

        <div className="col-sm-12 mb-1">
          <strong><IntlMessage id="dependents" /></strong>
          {': '}
          {!_.isEmpty( data.csEntityChilds )
            ? data.csEntityChilds.map( dependent => ( dependent.csChildEntityObject ? dependent.csChildEntityObject.objectName : '' ) ).join( ', ' )
            : '---'}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12">
          <h4 className="text-uppercase"><IntlMessage id="line" /></h4>
        </div>
        <div className="col-sm-4">
          <strong><IntlMessage id="start" /></strong>
          {': '}
          {data.lineStart
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
        <div className="col-sm-4">
          <strong><IntlMessage id="middle" /></strong>
          {': '}
          {data.lineMiddle
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
        <div className="col-sm-4">
          <strong><IntlMessage id="end" /></strong>
          {': '}
          {data.lineEnd
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button key="cancel" color="primary" variant="contained" className="jr-btn" onClick={onClose}>
        <i className="fa fa-times" />
        <IntlMessage id="close" />
      </Button>
    </DialogActions>
  </Dialog>
);
