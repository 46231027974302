import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-form';

import ContainerHeader from 'components/ContainerHeader';
import Confirmation from 'components/Custom/ConfirmationModal';
import Table from 'components/Custom/Table/Custom/';
import TableActions from 'components/Custom/Table/Actions/';
import TableHeader from 'components/Custom/Table/Header/';
import { TextField, Checkbox, Buttons } from 'components/Custom/FormElements';
import { required } from 'config/InputErrors';
import CardBox from 'components/CardBox/';
import LovServices from 'services/Lov';
import LovValuesServices from 'services/LovValue';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';
import matchSorter from 'match-sorter';
import LovValuesEdit from './components/LovValuesEdit';

class Edit extends Component {
  state = {
    data: {},
    lovValues: [],
    detailModal: false,
    alertModal: false,
    criteria: '',
  };

  entityId = null;
  entitySelected = {};
  lovValues = [];

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;
    if ( this.entityId ) this.getData();
  }

  getData = async () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    const response = await LovServices.getLov( this.entityId );
    toggleLoading( false );
    if ( response.ok ) {
      this.lovValues = [...response.data.csLovValues];
      this.setState( { data: response.data, lovValues: response.data.csLovValues } );
    } else toggleAlert( response.errors );
  };

  submitForm = async ( values ) => {
    const { history, toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const fixedValues = { ...values, lovInUse: values.lovInUse === true ? 1 : 2 };
    const response = this.entityId ? await LovServices.updateLov( this.entityId, fixedValues )
      : await LovServices.createLov( fixedValues );

    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );

    return history.push( '/lov' );
  };

  csValueSubmitForm = async ( values ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const response = this.entitySelected.id
      ? await LovValuesServices.updateLovValue( this.entitySelected.id, {
        ...values,
        csLov: this.entityId,
      } )
      : await LovValuesServices.createLovValue( { ...values, csLov: this.entityId } );

    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );

    this.entitySelected = {};
    this.toggleEditModal();
    this.getData();
  };

  toggleEditModal = ( entity ) => {
    if ( entity ) this.entitySelected = entity;
    this.setState( prevState => ( { detailModal: !prevState.detailModal } ) );
  };

  toggleAlertModal = ( event, entity ) => {
    if ( entity ) this.entitySelected = entity;
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  deleteEntity = async () => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;
    if ( !isLoading ) toggleLoading( true );

    if ( this.entitySelected.id ) {
      const response = await LovValuesServices.deleteLovValue( this.entitySelected.id );
      toggleLoading( false );
      if ( response.ok ) {
        this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
        this.getData();
      } else toggleAlert( response.errors );
    }
  };

  filterValues = ( val ) => {
    const result = matchSorter( this.lovValues, val, { keys: ['id', 'lovValue', 'lovValue2', 'codExt'] } );
    this.setState( { criteria: val, lovValues: result } );
  };

  render() {
    const { data, criteria, alertModal, detailModal, lovValues } = this.state;

    return (
      <div>
        <ContainerHeader />
        <CardBox
          heading={this.entityId ? 'editLov' : 'createLov'}
          headingTranslateValues={{ name: data.lovName }}
        >
          <div>
            <Form
              values={{ ...data, lovInUse: data.lovInUse === 1 }}
              onSubmit={this.submitForm}
              validate={values => ( { lovName: required( values.lovName ) } )}
            >
              {( { submitForm } ) => (
                <form onSubmit={submitForm} className="row mb-4">
                  <div className="col-sm-6">
                    <TextField field="lovName" label="name" />
                  </div>

                  <div className="col-sm-6">
                    <TextField field="lovDescription" label="description" />
                  </div>

                  <div className="col-sm-6">
                    <Checkbox field="lovInUse" optionValue="active" />
                  </div>

                  <div className="col-md-12 text-right">
                    <Buttons cancelTo="/lov" />
                  </div>
                </form>
              )}
            </Form>

            {!!this.entityId
            && (
              <div className="border-top">
                <div className="my-4">
                  <TableHeader
                    styleTitle
                    title="values"
                    criteria={criteria}
                    onFilter={this.filterValues}
                    newAction={() => this.toggleEditModal()}
                  />
                </div>

                <Table
                  rowsText="values"
                  data={lovValues}
                  columns={[
                    { Header: 'ID', accessor: 'id' },
                    { Header: <IntlMessage id="value" />, accessor: 'lovValue' },
                    { Header: <IntlMessage id="value2" />, accessor: 'lovValue2' },
                    { Header: <IntlMessage id="igeaId" />, accessor: 'codExt' },
                    {
                      maxWidth: 120,
                      className: 'justify-content-center',
                      sortable: false,
                      Cell: props => (
                        <TableActions
                          onEdit={() => this.toggleEditModal( props.original )}
                          onDelete={event => this.toggleAlertModal( event, props.original )}
                        />
                      ),
                    },
                  ]}
                />

                <Confirmation
                  open={alertModal}
                  translateValues={{
                    name: this.entitySelected ? this.entitySelected.lovValue : '',
                    type: 'value',
                  }}
                  onClose={this.toggleAlertModal}
                  onSubmit={this.deleteEntity}
                />
                <LovValuesEdit
                  open={detailModal}
                  onClose={() => this.toggleEditModal()}
                  onSubmit={this.csValueSubmitForm}
                  data={this.entitySelected}
                />
              </div>
            )}
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Edit );
