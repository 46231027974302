import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Form } from 'react-form';
import { connect } from 'react-redux';
import async from 'async';
import _ from 'lodash';

import logo from 'assets/images/map-logo.png';
import IntlMessage from 'util/IntlMessages';
import { TextField } from 'components/Custom/FormElements';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import AuthService from 'services/Auth';
import UserService from 'services/User';
import { required } from 'config/InputErrors';
import languageSwitcherData from 'components/LanguageSwitcher/data';

class Login extends React.Component {
  componentDidMount() {
    const { accessToken, isLoading, logoutUser, toggleLoading } = this.props;

    if ( accessToken ) logoutUser();
    if ( isLoading ) toggleLoading( false );
  }

  getSalt = async ( data, cb ) => {
    const response = await AuthService.getSalt( data );

    if ( response.errors ) return cb( response.errors );
    return cb( null, { ...response.data, ...data } );
  };

  loginUser = async ( data, cb ) => {
    if ( !data.saltPublic ) return cb( 'API_ERROR' );
    const response = await AuthService.loginUser( data );
    return cb( response.errors, response.data );
  };

  getUser = async ( data, cb ) => {
    const response = await UserService.getUsers( { me: true }, data.access_token );
    if ( response.errors ) return cb( response.errors );
    cb( null, {
      ...data,
      id: response.data.id,
      name: response.data.name,
      lastName: response.data.lastname,
      roles: response.data.roles,
    } );
  };

  submitForm = ( data ) => {
    const { history, location, loginUser, toggleLoading, toggleAlert, switchLanguage } = this.props;

    toggleLoading( true );

    async.waterfall( [
      this.getSalt.bind( this, data ),
      this.loginUser,
      this.getUser,
    ], ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      loginUser( results );
      const locale = _.find( languageSwitcherData, { locale: results.language } );
      if ( locale ) switchLanguage( locale );
      if ( location.pathname === '/login' ) history.push( '/' );
      window.location.reload();
    } );
  };

  render() {
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
      >
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg p-5" to="/" title="Continuity Software">
              <img className="img-fluid" src={logo} alt="Continuity Software" title="Continuity Software" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessage id="login" /></h1>
            </div>
            <div className="app-login-form">
              <Form
                onSubmit={this.submitForm}
                validate={values => ( {
                  username: required( values.username ),
                  password: required( values.password ),
                } )}
              >
                {( { submitForm } ) => (
                  <form onSubmit={submitForm} noValidate>
                    <TextField field="username" label="login_username" fullWidth />
                    <TextField field="password" label="password" type="password" fullWidth />

                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Button variant="contained" color="primary" className="jr-btn" type="submit">
                        <IntlMessage id="login" />
                      </Button>

                      <Link to="/downloads">
                        <IntlMessage id="publicDashboard" />
                      </Link>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
} );

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  switchLanguage: SettingsActions.switchLanguage,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Login );
