import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import IntlMessage from 'util/IntlMessages';

export default ( { children } ) => {
  return (
    <ExpansionPanel className="mb-3">
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h4 className="m-0"><IntlMessage id="advancedSearch" /></h4>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className="col-md-12 p-0">
          {children}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
