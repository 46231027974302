import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'components/CustomScrollbars';

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName( 'menu' );
    for ( let i = 0; i < menuLi.length; i++ ) {
      menuLi[i].onclick = function ( event ) {
        for ( let j = 0; j < menuLi.length; j++ ) {
          const parentLi = that.closest( this, 'li' );
          if ( menuLi[j] !== this && ( parentLi === null || !parentLi.classList.contains( 'open' ) ) ) {
            menuLi[j].classList.remove( 'open' );
          }
        }
        this.classList.toggle( 'open' );
      };
    }

    const activeLi = document.querySelector( `a[href="${pathname}"]` );// select current a element
    try {
      const activeNav = this.closest( activeLi, 'ul' ); // select closest ul
      if ( activeNav.classList.contains( 'sub-menu' ) ) {
        this.closest( activeNav, 'li' ).classList.add( 'open' );
      } else {
        this.closest( activeLi, 'li' ).classList.add( 'open' );
      }
    } catch ( error ) {

    }
  }

  componentWillReceiveProps( nextProps ) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector( `a[href="${pathname}"]` );// select current a element
    try {
      const activeNav = this.closest( activeLi, 'ul' ); // select closest ul
      if ( activeNav.classList.contains( 'sub-menu' ) ) {
        this.closest( activeNav, 'li' ).classList.add( 'open' );
      } else {
        this.closest( activeLi, 'li' ).classList.add( 'open' );
      }
    } catch ( error ) {

    }
  }

  closest( el, selector ) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some( ( fn ) => {
        if ( typeof document.body[fn] === 'function' ) {
          matchesFn = fn;
          return true;
        }
        return false;
      } );

      let parent;

      // traverse parents
      while ( el ) {
        parent = el.parentElement;
        if ( parent && parent[matchesFn]( selector ) ) {
          return parent;
        }
        el = parent;
      }
    } catch ( e ) {

    }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="menu no-arrow">
            <NavLink to="/">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarHome" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/entity-object">
              <i className="zmdi zmdi-labels zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarEntityObject" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/lov">
              <i className="zmdi zmdi-storage zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarLov" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/works">
              <i className="zmdi zmdi-case zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarWork" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/lov-meter">
              <i className="zmdi zmdi-chart zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarLovMeter" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/users">
              <i className="zmdi zmdi-account zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarUsers" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/groups">
              <i className="zmdi zmdi-accounts zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarGroups" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/settings">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarSettings" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/reports">
              <i className="zmdi zmdi-collection-text zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebarReports" />
              </span>
            </NavLink>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter( SidenavContent );
