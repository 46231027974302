import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { Select, TextField } from 'components/Custom/FormElements/';
import Translate from 'util/IntlMessages';

export default class ReactTablePagination extends Component {
  state = { page: this.props.page }; // eslint-disable-line

  getSafePage = ( page ) => {
    if ( Number.isNaN( page ) ) {
      page = this.props.page;
    }
    return Math.min( Math.max( page, 0 ), this.props.pages - 1 );
  };

  changePage = ( newPage ) => {
    const safePage = this.getSafePage( newPage );
    const { page, onPageChange } = this.props;

    this.setState( { page: safePage } );
    if ( page !== safePage ) {
      onPageChange( safePage );
    }
  };

  applyPage = ( e ) => {
    if ( e ) e.preventDefault();
    const { page } = this.state;
    this.changePage( page === '' ? this.props.page : page );
  };

  render() {
    const {
      pages,
      page,
      showPageSizeOptions,
      pageSizeOptions,
      showPageJump,
      canPrevious,
      canNext,
      className,
      onPageSizeChange,
      pageSize,
      ofText,
      rowsText,
    } = this.props;
    const pageSizeOptionsMapped = _.map( pageSizeOptions, item => ( { id: item, name: item } ) );

    return (
      <div className={classnames( className, '-pagination' )}>
        <div className="-pageInfo w-100">
          <div className="row">
            <div className="col-12 col-sm-6 text-center text-sm-left px-2">
              <span className="text-muted text-small"><Translate id="page" /></span>
              <i
                className={classnames( 'fas fa-angle-left p-2', {
                  'text-muted': !canPrevious,
                  pointer: canPrevious,
                } )}
                onClick={() => {
                  if ( !canPrevious ) return;
                  this.changePage( page - 1 );
                }}
                disabled={!canPrevious}
              />

              {showPageJump
                ? (
                  <div className="-pageJump">
                    <TextField
                      marginContainer={false}
                      type={this.state.page === '' ? 'text' : 'number'}
                      onChange={( val ) => {
                        const page = val - 1;
                        if ( val === '' ) {
                          return this.setState( { page: val } );
                        }
                        this.setState( { page: this.getSafePage( page ) } );
                      }}
                      value={this.state.page === '' ? '' : this.state.page + 1}
                      onBlur={this.applyPage}
                      onKeyPress={( e ) => {
                        if ( e.which === 13 || e.keyCode === 13 ) {
                          this.applyPage();
                        }
                      }}
                    />
                  </div>
                )
                : (
                  <span className="-currentPage">{page + 1}</span>
                )
              }
              <h5 className="d-inline m-0">
                <i
                  className={classnames( 'fas fa-angle-right p-2', {
                    'text-muted': !canNext,
                    pointer: canNext,
                  } )}
                  onClick={() => {
                    if ( !canNext ) return;
                    this.changePage( page + 1 );
                  }}
                />
              </h5>

              <span className="text-muted text-small">
                {ofText}
                {' '}
                <span className="-totalPages">{pages || 1}</span>
              </span>
            </div>

            <div className="col-12 col-sm-6">
              {showPageSizeOptions
              && (
                <div className="mr-2 align-items-center justify-content-sm-end justify-content-center d-flex">
                  <Select
                    translateOptions={false}
                    marginContainer={false}
                    fullWidth={false}
                    emptyValue={false}
                    onChange={onPageSizeChange}
                    options={pageSizeOptionsMapped}
                    value={pageSize}
                    labelKey="name"
                  />
                  {' '}
                  <span className="text-muted text-small pl-1">{rowsText}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
