import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { Select } from 'components/Custom/FormElements/';
import { AdvancedSearch } from 'components/Custom/Panels/';
import Confirmation from 'components/Custom/ConfirmationModal';
import Table from 'components/Custom/Table/AsyncTable/';
import TableActions from 'components/Custom/Table/Actions/';
import TableHeader from 'components/Custom/Table/Header/';
import CardBox from 'components/CardBox/';
import WorkServices from 'services/Works';
import SearchParams from 'util/SearchParams';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';

class Dashboard extends React.Component {
  state = {
    data: [],
    search: {},
    criteria: null,
    alertModal: false,
  };
  refId = '0';
  entitySelected = null;

  getData = async ( params, loading = true ) => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;
    const { search, criteria } = this.state;
    if ( loading && !isLoading ) toggleLoading( true );

    params = SearchParams.getSearchParams( { ...search, criteria, refId: this.refId }, params );
    this.params = _.cloneDeep( params );

    const response = await WorkServices.getWorks( params );
    toggleLoading( false );

    if ( response.ok ) {
      this.setState( {
        data: response.data.data || [],
        pages: Math.ceil( response.data.total / params.limit ),
        total: response.data.total,
      } );
    } else toggleAlert( response.errors );
  };

  clearSearch = () => {
    this.setState( { search: { workStatus: null } }, () => this.getData( this.params ) );
  };

  filterData = ( val ) => {
    if ( val ) {
      this.setState( { criteria: val }, () => this.getData( this.params, false ) );
    } else {
      this.setState( { criteria: null }, () => this.getData( this.params, false ) );
    }
  };

  deleteEntity = async () => {
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  toggleAlertModal = ( entity ) => {
    if ( entity ) this.entitySelected = entity;
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  render() {
    const { search, criteria, alertModal, ...rest } = this.state;

    return (
      <div>
        <CardBox heading={(
          <TableHeader
            title="works"
            criteria={criteria}
            onFilter={this.filterData}
          />
        )}
        >
          <div>
            <AdvancedSearch>
              <div className="row">
                <div className="col-sm-3">
                  <Select
                    marginContainer={false}
                    value={search.workStatus}
                    onChange={val => this.setState( {
                      search: {
                        ...search,
                        workStatus: val,
                      },
                    } )}
                    placeholder="status"
                    labelKey="name"
                    options={[
                      { id: 'DOWNLOADED', name: 'DOWNLOADED' },
                      { id: 'APPROVED', name: 'APPROVED' },
                      { id: 'DELETE', name: 'DELETE' },
                      { id: 'START_UPLOAD', name: 'START_UPLOAD' },
                      { id: 'UPLOADED', name: 'UPLOADED' },
                    ]}
                  />
                </div>
                <div className="col-sm-3 d-flex mb-4">
                  <Button
                    variant="contained"
                    className="jr-btn"
                    color="primary"
                    onClick={() => this.getData( this.params )}
                  >
                    <i className="fa fa-search" />
                  </Button>
                  <Button
                    variant="contained"
                    className="jr-btn"
                    color="primary"
                    onClick={this.clearSearch}
                  >
                    <i className="fa fa-trash-alt" />
                  </Button>
                </div>
              </div>
            </AdvancedSearch>

            <Table
              onFetchData={this.getData}
              rowsText="works"
              {...rest}
              columns={[
                { Header: 'ID', accessor: 'id', maxWidth: 80 },
                {
                  Header: <IntlMessage id="updatedAt" />,
                  accessor: 'update_date',
                  id: 'updateDate',
                  Cell: props => (
                    <div className="text-justify">
                      {moment( new Date( props.value ) ).format( 'DD/MM/YYYY hh:mm A' )}
                    </div>
                  ),
                },
                {
                  Header: <IntlMessage id="name" />,
                  accessor: 'work_name',
                  id: 'workName',
                  Cell: props => (
                    <Link to={`/works/detail/${props.original.id}`}>
                      <button
                        className="m-0 p-0 bg-transparent border-0 text-primary"
                        type="button"
                      >
                        {props.value}
                      </button>
                    </Link>
                  ),
                },
                {
                  maxWidth: 110,
                  Header: <IntlMessage id="status" />,
                  accessor: 'work_status',
                  id: 'workStatus',
                  Cell: ( props ) => {
                    switch ( props.value ) {
                      case 'REJECTED':
                        return (
                          <Badge color="danger" className="text-white text-uppercase">
                            <IntlMessage id={props.value} />
                          </Badge>
                        );
                      case 'APPROVED':
                        return (
                          <Badge color="success" className="text-white text-uppercase">
                            <IntlMessage id={props.value} />
                          </Badge>
                        );
                      case 'UPLOADED':
                        return (
                          <Badge color="warning" className="text-white text-uppercase">
                            <IntlMessage id={props.value} />
                          </Badge>
                        );
                      default:
                        return (
                          <Badge color="light" className="text-uppercase">
                            <IntlMessage id={props.value} />
                          </Badge>
                        );
                    }
                  },
                },
                { Header: <IntlMessage id="igeaId" />, accessor: 'codExt', maxWidth: 80 },
                { Header: <IntlMessage id="user" />, accessor: 'user.name', id: 'user' },
                {
                  Header: <IntlMessage id="downloadUser" />,
                  accessor: 'user_download.name',
                  id: 'userDownload',
                },
                {
                  maxWidth: 60,
                  className: 'justify-content-center',
                  sortable: false,
                  Cell: props => (
                    <div>
                      <TableActions
                        onDelete={() => this.toggleAlertModal( props.original )}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </CardBox>
        <Confirmation
          open={alertModal}
          translateValues={{ name: this.entitySelected ? this.entitySelected.work_name : '', type: 'work' }}
          onClose={this.toggleAlertModal}
          onSubmit={this.deleteEntity}
        />
      </div>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Dashboard );
