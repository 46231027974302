import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Translate from 'util/IntlMessages';

export default ( { onClickCancel, cancelTo } ) => (
  [
    <Button key="save" variant="contained" color="primary" className="jr-btn text-white" type="submit">
      <i className="fa fa-save" />
      <Translate id="save" />
    </Button>,

    onClickCancel
      ? (
        <Button key="cancel" variant="contained" className="jr-btn" onClick={onClickCancel}>
          <i className="fa fa-times" />
          <Translate id="cancel" />
        </Button>
      )
      : (
        <Button key="cancel" variant="contained" className="jr-btn" component={Link} to={cancelTo}>
          <i className="fa fa-times" />
          <Translate id="cancel" />
        </Button>
      ),
  ]
);
