import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide, Button } from '@material-ui/core';
import moment from 'moment';

import IntlMessage from 'util/IntlMessages';

export default ( { open, data, onClose } ) => (
  <Dialog onClose={onClose} TransitionComponent={Slide} open={open}>
    <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
      <IntlMessage id="lovMeter" />
      {` ID #${data.id || ''}`}
    </DialogTitle>
    <DialogContent>
      <div className="row">
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="meterNumber" /></strong>
          {': '}
          {data.meterSerialNumer}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="updatedAt" /></strong>
          {': '}
          {data.updateDate ? moment( new Date( data.updateDate ) ).format( ' DD/MM/YYYY hh:mm A' ) : ''}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="meterManufacturer" /></strong>
          {': '}
          {data.meterManufacturer}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="paymentMode" /></strong>
          {': '}
          {data.paymentMode}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="codExt" /></strong>
          {': '}
          {data.codExt}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="isActive" /></strong>
          {': '}
          {data.lovInUse
            ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </div>
        <div className="col-sm-6 mb-1">
          <strong>
            <IntlMessage
              id="customerName"
            />
          </strong>
          {': '}
          {data.customerFirstName}
          {' '}
          {data.customerSurname}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="email" /></strong>
          {': '}
          {data.email}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="poleNumber" /></strong>
          {': '}
          {data.poleNumber}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="supplyPhase" /></strong>
          {': '}
          {data.supplyPhase}
        </div>
        <div className="col-sm-6 mb-1">
          <strong><IntlMessage id="supplyVoltage" /></strong>
          {': '}
          {data.supplyVoltage}
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <div className="col-md-12 text-right">
        <Button key="cancel" color="primary" variant="contained" className="jr-btn" onClick={onClose}>
          <i className="fa fa-times" />
          <IntlMessage id="close" />
        </Button>
      </div>
    </DialogActions>
  </Dialog>
);
