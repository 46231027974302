import React from 'react';

import IntlMessage from 'util/IntlMessages';

const CardBox = ( { heading, children, styleName, cardStyle, childrenStyle, headerOutside, headingStyle, headingTranslateValues } ) => {

  return (
    <div className={`${styleName}`}>
      {headerOutside &&
      <div className="jr-entry-header">
        <h3 className="entry-heading">{
          ( typeof heading === 'string' ) ? <IntlMessage id={heading} values={headingTranslateValues}/> : heading}</h3>
        {children.length > 1 && <div className="entry-description">{children[0]}</div>}
      </div>
      }

      <div className={`jr-card ${cardStyle}`}>
        {!headerOutside &&
        ( heading &&
          <div className={`jr-card-header mb-4 ${headingStyle}`}>
            <h3 className="card-heading">{
              ( typeof heading === 'string' ) ?
                <IntlMessage id={heading} values={headingTranslateValues}/> : heading}</h3>
            {children.length > 1 && <div className="sub-heading">{children[0]}</div>}
          </div>
        )}
        <div className={`jr-card-body ${childrenStyle}`}>
          {children.length > 1 ? children[1] : children}
        </div>
      </div>
    </div>
  )
};

export default CardBox;

CardBox.defaultProps = {
  cardStyle: '',
  headingStyle: '',
  childrenStyle: '',
  styleName: 'p-3'
};
