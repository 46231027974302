import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import _ from 'lodash';

import VersionService from 'services/Version';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'util/IntlMessages';
import CardBox from 'components/CardBox';

class Dashboard extends Component {
  state = { data: [] };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { toggleLoading } = this.props;
    const params = {};
    params['filters[enabled]'] = 1;
    params['order_by[id]'] = 'DESC';

    toggleLoading( true );
    const response = await VersionService.getVersions( params );
    if ( response.ok ) {
      this.setState( { data: response.data.data } );
    }
    toggleLoading( false );
  }

  render() {
    const { data } = this.state;

    return (
      <div className="row">
        {_.map( data, item => (
          <div className="col-md-6" key={item.id}>
            <CardBox>
              <div className="row align-items-center">
                <i className="fa fa-arrow-alt-circle-down fa-6x" style={{ color: 'rgba(0, 0, 0, 0.4)' }} />

                <div className="ml-3">
                  <div className="jr-card-header mb-2">
                    <h3 className="card-heading">
                      {item.title}
                      {' '}
                      {item.version && `V${item.version}`}
                    </h3>
                    <p className="sub-heading">{item.description}</p>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-uppercase jr-btn-sm text-white"
                    disabled={!item.path}
                    component="a"
                    href={item.path}
                    target="_blank"
                  >
                    <i className="fa fa-arrow-down mr-2" />
                    <span><IntlMessages id="dashboard.download" /></span>
                  </Button>
                  {!!item.pathDocumentation
                  && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="text-uppercase jr-btn-sm text-white ml-lg-2"
                      component="a"
                      href={item.pathDocumentation}
                      target="_blank"
                    >
                      <i className="fa fa-arrow-down mr-2" />
                      <span><IntlMessages id="dashboard.downloadDoc" /></span>
                    </Button>
                  )}
                </div>
              </div>
            </CardBox>
          </div>
        ) )}
      </div>
    );
  }
}

const mapDispatchToProps = ( { toggleLoading: SettingsActions.toggleLoading } );

export default connect(
  null,
  mapDispatchToProps,
)( Dashboard );
