import api from '../config/Api';

const baseUrl = 'v1.0/csentitychildren';

export default {
  createEntityChildren( data ) {
    return api.post( baseUrl, data );
  },

  deleteChildren( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },

};
