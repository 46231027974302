import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import Detail from './Detail';

export default ( { match } ) => (
  <Switch>
    <Route path={`${match.path}/detail/:id`} component={Detail} />
    <Route path={`${match.path}`} component={Dashboard} />
  </Switch>
);
