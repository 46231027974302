import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import Edit from './Edit';
import ResetPassword from './ResetPassword';

export default ( { match } ) => (
  <Switch>
    <Route path={`${match.path}/edit/:id`} component={Edit} />
    <Route path={`${match.path}/new`} component={Edit} />
    <Route path={`${match.path}/reset-password/:id`} component={ResetPassword} />
    <Route path={`${match.path}`} component={Dashboard} />
  </Switch>
);
