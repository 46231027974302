import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import _ from 'lodash';
import 'assets/vendors/style';

import RTL from 'components/Rtl';
import AppLocale from 'lngProvider/';
import defaultTheme from 'config/themes/defaultTheme';
import MainApp from 'routes/';
import Login from 'routes/Login';
import Dashboard from 'routes/PublicDashboard';
import Error404 from 'components/Error404';
import SettingActions from 'store/reducers/Settings';
import IntlMessages from 'util/IntlMessages';

class App extends Component {
  componentDidUpdate( prevProps ) {
    const { alert, toggleAlert } = this.props;
    if ( alert && alert.msg && alert.date !== _.get( prevProps, 'alert.date' ) ) {
      if ( alert.msg === 'dataSaved' ) {
        return NotificationManager.success( <IntlMessages id="dataSaved" />,
          <IntlMessages id="alert.success" />, 5000, () => { toggleAlert( null ); } );
      }
      switch ( alert.type ) {
        case 'info':
          return NotificationManager.info( alert.msg, <IntlMessages id="alert.info" />, 5000, () => {
            toggleAlert( null );
          } );
        case 'success':
          return NotificationManager.success( alert.msg, <IntlMessages id="alert.success" />, 5000,
            () => {
              toggleAlert( null );
            } );
        default:
          return NotificationManager.error( alert.msg, <IntlMessages id="alert.error" />, 5000, () => {
            toggleAlert( null );
          } );
      }
    }
  }

  render() {
    const { location, locale, isDirectionRTL, isLoading, user } = this.props;
    if ( location.pathname === '/' ) return ( <Redirect to="/dashboard/" /> );

    const applyTheme = createMuiTheme( defaultTheme );
    if ( isDirectionRTL ) {
      applyTheme.direction = 'rtl';
      document.body.classList.add( 'rtl' );
    } else {
      document.body.classList.remove( 'rtl' );
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/downloads" component={Dashboard} />
                  <Route path="/" component={user.access_token ? MainApp : Login} />
                  <Route component={Error404} />
                </Switch>

                {isLoading
                && (
                  <div className="app-loading">
                    <CircularProgress size={50} thickness={5} />
                  </div>
                )}

                <NotificationContainer />
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ( { settings, user } ) => {
  const { sideNavColor, locale, isDirectionRTL, isLoading, alert } = settings;
  return { sideNavColor, locale, isDirectionRTL, isLoading, alert, user };
};

const mapDispatchToProps = ( { toggleAlert: SettingActions.toggleAlert } );

export default connect( mapStateToProps, mapDispatchToProps )( App );
