import crypto from 'crypto';
import api from '../config/Api';

export default {

  loginUser( data ) {
    const { username, password, saltPublic } = data;
    const saltPlane = atob( saltPublic );
    const saltInv = saltPlane.substring( saltPlane.indexOf( ':' ) + 1, saltPlane.length );
    const salt = saltInv.split( '' ).reverse().join( '' );

    const toEncrypt = `${username}.${salt}{${password}}`;
    const hash = crypto.createHash( 'sha512' );
    const hashData = hash.update( toEncrypt );
    const genHash = hashData.digest( 'hex' );
    const finalJSON = {
      grant_type: 'http://conn.com/auth/web',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      data: btoa( JSON.stringify( { username, password: genHash } ) ),
    };
    return api.post( 'oauth/v2/token', finalJSON );
  },

  getSalt( data ) {
    const { username } = data;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const code = btoa( `${username}:${clientId}` );
    const jsonSalt = {
      date: Date.now(),
      clientId,
      code,
    };
    return api.post( 'v1.0/public/salt', { data: btoa( JSON.stringify( jsonSalt ) ) } );
  },
};
