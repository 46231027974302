import React from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';

import TablePagination from '../Pagination/';
import IntlMessage from 'util/IntlMessages';

class CustomTable extends React.Component {

  render() {
    const { showPageSizeOptions, rowsText, ...rest } = this.props;

    return (
      <ReactTable className={`app-table -striped -highlight`}
                  defaultPageSize={10}
                  minRows={0}
                  pageSizeOptions={[10, 20, 30]}
                  resizable={false}
                  showPaginationBottom={false}
                  showPaginationTop
                  showPageSizeOptions={_.isUndefined( showPageSizeOptions ) ? true : showPageSizeOptions}
                  PaginationComponent={( props ) =>
                    <TablePagination {...props}/>
                  }
                  {...rest}
                  rowsText={!!rowsText ? <IntlMessage id={rowsText}/>: ""}
      />
    )
  }
}

export default CustomTable;
