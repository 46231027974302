import api from '../config/Api';

const baseUrl = 'v1.0/cslovmeters';

export default {
  getAll( params ) {
    return api.get( baseUrl, params );
  },

  createCsLovMeter( data ) {
    return api.post( `${baseUrl}`, data );
  },

  getCsLovMeter( id ) {
    return api.get( `${baseUrl}/${id}` );
  },

  updateCsLovMeter( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },

  deleteCsLovMeter( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },
};
