import React from 'react';
import { Field } from 'react-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import {injectIntl} from 'react-intl';

import Translate from 'util/IntlMessages';

const CustomCheckbox = ( { containerClass, field, label, onBlur, onChange, optionValue, value, marginContainer, ...props } ) => (
  field ?
    <Field field={field} pure={false}>
      {( { error, value, setValue, setTouched, touched } ) => (
        <FormControl error={touched && !!error} className={classNames( 'w-100 mb-4', containerClass )}>
          <FormControlLabel
            label={<Translate id={optionValue}/>}
            control={(
              <Checkbox
                color="primary"
                {...props}
                checked={!!value || false}
                onChange={( e ) => {
                  setValue( e.target.checked );
                  if( onChange ) onChange( e.target.checked );
                }}
                onBlur={( e ) => {
                  setTouched();
                  if( onBlur ) onBlur( e );
                }}
              />
            )}
          />
          {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field> :
    <FormControl className={classNames(containerClass, {
      'mb-4': marginContainer,
    } )}>
      <FormControlLabel
        label={<Translate id={optionValue}/>}
        control={(
          <Checkbox
            color="primary"
            {...props}
            checked={!!value || false}
            onChange={( e ) => {
              if( onChange ) onChange( e.target.checked );
            }}
            onBlur={( e ) => {
              if( onBlur ) onBlur( e );
            }}
          />
        )}
      />
    </FormControl>
);

export default injectIntl(CustomCheckbox);