import api from '../config/Api';

const baseUrl = 'v1.0/users';

export default {

  getUsers( params, accessToken ) {
    if ( accessToken ) return api.get( baseUrl, params, { headers: { Authorization: `Bearer ${accessToken}` } } );
    return api.get( baseUrl, params );
  },

  getUser( id ) {
    return api.get( `${baseUrl}/${id}` );
  },

  partialUpdateUser( id, data ) {
    return api.patch( `${baseUrl}/${id}`, data );
  },

  updateUser( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },

  createUser( data ) {
    return api.post( baseUrl, data );
  },

};
