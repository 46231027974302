import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { MenuItem, Chip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CancelIcon from '@material-ui/icons/Cancel';

const customStyles = {
  option: ( provided, state ) => ( {
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  } ),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2
  }),
  control: () => ( {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    height: 'auto',
    minWidth: '50px',
    background: 'transparent',
    ':hover': { boxShadow: 'none' },
  } ),
  singleValue: ( provided, state ) => ( { ...provided, paddingBottom: '4.7px', marginLeft: 0 } ),
  multiValue: ( provided, state ) => ( { ...provided, paddingBottom: '4.7px' } ),
  valueContainer: ( provided, state ) => ( {
    ...provided,
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '0px',
    padding: 0
  } ),
  input: ( provided, state ) => ( {
    ...provided,
    marginLeft: 0,
    paddingBottom: '4.7px'
  } ),
  clearIndicator: ( provided ) => ({
    ...provided,
    padding: '0'
  }),
  placeholder: ( provided, state ) => ( {
    ...provided,
    marginLeft: 0,
    paddingBottom: '4.7px'
  } ),
  indicatorSeparator: ( provided, state ) => ( { ...provided, width: 0 } ),
};

class Option extends React.Component {
  handleClick = ( event ) => {
    const { selectOption, data } = this.props;
    selectOption( data );
  };

  render() {
    const { data, children, isFocused, onFocus } = this.props;

    return (
      <MenuItem onFocus={onFocus} selected={isFocused} onClick={this.handleClick}>
        {!!data.icon && <span className="mr-1">{data.icon}</span>}{children}
      </MenuItem>
    );
  }
}

function SelectWrapper( { inputRef, ...props } ) {
  return (
    <Select
      clearable={false}
      classNamePrefix="react-select"
      styles={customStyles}
      components={{
        Option,
        DropdownIndicator: arrowProps => {
          return arrowProps.isFocused ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>
        },
        NoOptionsMessage: () => <div className="p-2 text-muted">No results found</div>,
        SingleValue: props => {
          if( props.selectProps && props.selectProps.hasIcon ) {
            const option = _.find( props.options, { value: props.data.value } );
            return <span>{( !!option && !!option.icon ) &&
            <span className="mr-1">{option.icon}</span>}{props.children}</span>
          }
          return <span>{props.children}</span>
        },
        MultiValue: ( valueProps ) => {
          const { data, children, setValue } = valueProps;
          let value = _.filter( valueProps.getValue(), ( item ) => item.value !== data.value );

          const onDelete = ( event ) => {
            event.preventDefault();
            event.stopPropagation();
            setValue( value );
          };

          if( setValue ) {
            return (
              <Chip
                tabIndex={-1}
                label={children}
                deleteIcon={<CancelIcon onTouchEnd={onDelete} style={{ width: '19px' }}/>}
                onDelete={onDelete}
                style={{ height: '20px', marginBottom: '5px' }}
              />
            );
          }

          return <div className="Select-value">{children}</div>;
        }
      }}
      {...props}
      value={props.isMulti ? props.value.map( item => ( {
          value: item,
          label: props.options.find( o => o.value === item ).label,
        } ) ) :
        props.value && {
          value: props.value,
          label: props.options.find( o => o.value === props.value ).label,
        }
      }
    />
  );
}

export default SelectWrapper;
