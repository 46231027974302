import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-form';
import _ from 'lodash';

import ContainerHeader from 'components/ContainerHeader';
import { TextField, Checkbox, Buttons, Select } from 'components/Custom/FormElements';
import { required } from 'config/InputErrors';
import CardBox from 'components/CardBox/';
import EntityObjectService from 'services/EntityObject';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';
import matchSorter from 'match-sorter';
import {
  defaultImg,
  marker1,
  marker2,
  marker3,
  marker4,
  marker5,
  tower1,
  tower2,
  tower3,
  tower4,
  substation1,
  substation2,
} from 'assets/images/markers';
import EntityAttributesList from './components/EntityAttributesList';
import EntityChildrenList from './components/EntityChildrenList';

const ImageComponent = ( { img } ) => <img alt="Default" src={img} style={{ width: '20px' }} />;

class Edit extends Component {
  state = {
    data: {},
    entityAttributes: [],
    entityChildren: [],
    criteria: '',
  };

  entityId = null;
  entityAttributes = [];
  entityChildren = [];

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;
    if ( this.entityId ) this.getData();
  }

  getData = async () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    const response = await EntityObjectService.getEntityObject( this.entityId );
    toggleLoading( false );
    if ( response.ok ) {
      const { data } = response;
      this.entityAttributes = [...response.data.csEntityAttributes];
      this.entityChildren = [...response.data.csEntityChilds];
      data.identifierAttr = data.identifierAttr ? data.identifierAttr.id : null;
      this.setState( {
        data,
        entityAttributes: data.csEntityAttributes,
        entityChildren: data.csEntityChilds,
      } );
    } else toggleAlert( response.errors );
  };

  submitForm = async ( values ) => {
    const { history, toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const fixedValues = { ...values, objecActive: values.objecActive === true ? 1 : 2 };
    const response = this.entityId
      ? await EntityObjectService.updateEntityObject( this.entityId, fixedValues )
      : await EntityObjectService.createEntityObject( fixedValues );

    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );

    return history.push( '/entity-object' );
  };

  filterAttributes = ( val ) => {
    const result = matchSorter( this.entityAttributes, val, {
      keys: ['id', 'AttributeName', 'AttributeType', 'codExt', 'order'],
      threshold: matchSorter.rankings.STARTS_WITH,
    } );
    this.setState( { criteria: val, entityAttributes: result } );
  };

  filterChildren = ( val ) => {
    const result = matchSorter( this.entityChildren, val,
      {
        keys: [
          'csChildEntityObject.objectName',
          'csChildEntityObject.objecType',
          'csChildEntityObject.objecTopology',
          'csChildEntityObject.codExt',
        ],
        threshold: matchSorter.rankings.STARTS_WITH,
      } );
    this.setState( { childCriteria: val, entityChildren: result } );
  };

  getAttributeOptions = () => {
    const { data } = this.state;
    if ( !data.id ) return [];
    return _.filter( data.csEntityAttributes, item => !item.lov );
  };

  render() {
    const { data, criteria, childCriteria, entityAttributes, entityChildren } = this.state;

    return (
      <div>
        <ContainerHeader />
        <CardBox
          heading={this.entityId ? 'editEntityObject' : 'createEntityObject'}
          headingTranslateValues={{ name: data.objectName }}
        >
          <div>
            <Form
              values={{ ...data, objecActive: data.objecActive === 1 }}
              onSubmit={this.submitForm}
              validate={values => ( {
                objectName: required( values.objectName ),
                objecType: required( values.objecType ),
                objecTopology: required( values.objecTopology ),
              } )}
            >
              {( { submitForm } ) => (
                <form onSubmit={submitForm}>
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <TextField field="objectName" label="name" />
                    </div>
                    <div className="col-sm-6">
                      <TextField field="objecDescription" label="description" />
                    </div>
                    <div className="col-sm-6">
                      <Select
                        field="objecType"
                        label="type"
                        labelKey="name"
                        options={[
                          { id: 'Alphanumerical', name: 'Alphanumerical' },
                          { id: 'Graphical', name: 'Graphical' },
                        ]}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Select
                        field="objecTopology"
                        label="topology"
                        labelKey="name"
                        options={[
                          { id: 'Node', name: 'Node' },
                          { id: 'Line', name: 'Line' },
                        ]}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextField field="codExt" label="igeaId" />
                    </div>
                    <div className="col-sm-6">
                      <Select
                        field="identifierAttr"
                        label="attribute"
                        labelKey="AttributeName"
                        options={this.getAttributeOptions()}
                      />
                    </div>
                    <div className="col-sm-3">
                      <Checkbox field="objecActive" optionValue="active" />
                    </div>
                    <div className="col-sm-3">
                      <Checkbox field="firstLevel" optionValue="firstLevel" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="border-bottom">
                        <h4 className="text-uppercase"><IntlMessage id="line" /></h4>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-2">
                      <Checkbox field="lineStart" optionValue="start" />
                    </div>
                    <div className="col-sm-2">
                      <Checkbox field="lineMiddle" optionValue="middle" />
                    </div>
                    <div className="col-sm-2">
                      <Checkbox field="lineEnd" optionValue="end" />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <Select
                        hasIcon
                        field="marker"
                        label="marker"
                        labelKey="name"
                        translateOptions={false}
                        options={[
                          { id: 'default', name: 'Default', icon: <ImageComponent img={defaultImg} /> },
                          { id: 'marker1', name: 'Marker 1', icon: <ImageComponent img={marker1} /> },
                          { id: 'marker2', name: 'Marker 2', icon: <ImageComponent img={marker2} /> },
                          { id: 'marker3', name: 'Marker 3', icon: <ImageComponent img={marker3} /> },
                          { id: 'default', name: 'Marker 4', icon: <ImageComponent img={marker4} /> },
                          { id: 'default', name: 'Marker 5', icon: <ImageComponent img={marker5} /> },
                          { id: 'tower1', name: 'Tower 1', icon: <ImageComponent img={tower1} /> },
                          { id: 'tower2', name: 'Tower 2', icon: <ImageComponent img={tower2} /> },
                          { id: 'tower3', name: 'Tower 3', icon: <ImageComponent img={tower3} /> },
                          { id: 'tower4', name: 'Tower 4', icon: <ImageComponent img={tower4} /> },
                          { id: 'substation1', name: 'Substation 1', icon: <ImageComponent img={substation1} /> },
                          { id: 'substation2', name: 'Substation 2', icon: <ImageComponent img={substation2} /> },
                        ]}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-md-12 text-right">
                      <Buttons cancelTo="/entity-object" />
                    </div>
                  </div>
                </form>
              )}
            </Form>

            {!!this.entityId
            && (
              <div>
                <EntityAttributesList
                  data={entityAttributes}
                  entityId={this.entityId}
                  criteria={criteria}
                  onFilter={this.filterAttributes}
                  getData={this.getData}
                />
                <EntityChildrenList
                  data={entityChildren}
                  entityId={this.entityId}
                  criteria={childCriteria}
                  filterData={this.filterChildren}
                  getData={this.getData}
                />
              </div>
            )}
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Edit );
