import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  BELOW_THE_HEADER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from 'store/constants/ActionTypes';
import SettingsActions from 'store/reducers/Settings';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      userInfo: false,
      langSwitcher: false,
    }
  }

  onLangSwitcherSelect = ( event ) => {
    this.setState( {
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    } )
  };

  onUserInfoSelect = () => {
    this.setState( {
      userInfo: !this.state.userInfo
    } )
  };

  handleRequestClose = () => {
    this.setState( {
      langSwitcher: false,
      userInfo: false,
    } );
  };

  onToggleCollapsedNav = ( e ) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav( val );
  };

  render() {
    const { locale, navigationStyle, horizontalNavPosition, isPublic } = this.props;

    return (
      <AppBar
        className={`app-main-header ${( navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER ) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {!isPublic &&
          <IconButton className={`jr-menu-icon mr-3`} aria-label="Menu"
                      onClick={this.onToggleCollapsedNav}>
            <span className="menu-icon"/>
          </IconButton>
          }

          <Link className="app-logo mr-2 text-decoration-none d-none d-sm-block" to="/">
            <img src={require( "assets/images/map-logo.png" )} alt="Jambo" title="Jambo"/>
            <span className="text-white ml-2">Indra - Continuity Software</span>
          </Link>

          {( navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER ) &&
          <Menu/>}

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind( this )}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                    handleRequestClose={this.handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>


            </li>

            {!isPublic &&
            <li className="list-inline-item user-nav">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.userInfo}
                toggle={this.onUserInfoSelect.bind( this )}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-account"/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <UserInfoPopup/>
                </DropdownMenu>
              </Dropdown>
            </li>
            }
          </ul>
        </Toolbar>
      </AppBar>
    );
  }

}


const mapStateToProps = ( { settings } ) => {
  const { locale, navigationStyle, horizontalNavPosition, navCollapsed } = settings;
  return { locale, navigationStyle, horizontalNavPosition, navCollapsed }
};

export default withRouter( connect( mapStateToProps, {
  toggleCollapsedNav: SettingsActions.toggleCollapsedNav,
  switchLanguage: SettingsActions.switchLanguage
} )( Header ) );
