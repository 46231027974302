import api from '../config/Api';

const baseUrl = 'v1.0/csentityattributes';

export default {
  getEntityAttributes( params ) {
    return api.get( baseUrl, params );
  },

  createEntityAttribute( data ) {
    return api.post( `${baseUrl}`, data );
  },

  getEntityAttribute( id ) {
    return api.get( `${baseUrl}/${id}` );
  },

  updateEntityAttribute( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },

  deleteEntityAttribute( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },
};
