import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {};

const { Types, Creators } = createActions( {
  loginUser: ['data'],
  logoutUser: null,
}, {} );

const loginUser = ( state, { data } ) => ( { ...state, ...data } );
const logoutUser = () => ( {} );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.LOGIN_USER]: loginUser,
  [Types.LOGOUT_USER]: logoutUser,
} );

export default Creators;
