import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";

export default ( { onDelete, onEdit, editLink } ) => (
  [
    !!onEdit
    && (
      editLink ?
        <Button
          key={1}
          variant="contained"
          color="primary"
          className="jr-btn jr-btn-sm text-white"
          component={Link}
          to={onEdit}
        >
          <i className="fa fa-edit" />
        </Button> :
        <Button
          key={1}
          variant="contained"
          color="primary"
          className="jr-btn jr-btn-sm text-white"
          onClick={onEdit}
        >
          <i className="fa fa-edit"/>
        </Button>
    ),

    !!onDelete
    && (
      <Button
        key={2}
        variant="contained"
        className="jr-btn jr-btn-sm text-white bg-danger"
        onClick={onDelete}
      >
        <i className="fa fa-trash"/>
      </Button>
    )
  ]
);
