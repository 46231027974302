import api from '../config/Api';

const baseUrl = 'v1.0/configurations';

export default {

  getAll() {
    return api.get( baseUrl );
  },

  getById( id ) {
    return api.get( `${baseUrl}/${id}/config` );
  },

  updateSettings( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },
};
