import { createReducer, createActions } from 'reduxsauce';
import {
  FIXED_DRAWER,
  // COLLAPSED_DRAWER,
  // MINI_DRAWER,
  INSIDE_THE_HEADER,
  VERTICAL_NAVIGATION,
  // HORIZONTAL_NAVIGATION,
} from 'store/constants/ActionTypes';

const rltLocale = ['ar'];
export const INITIAL_STATE = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  isLoading: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb',
  },
};

const { Types, Creators } = createActions( {
  toggleCollapsedNav: ['isNavCollapsed'],
  updateWindowWidth: ['width'],
  switchLanguage: ['locale'],
  toggleAlert: ['msg', 'category'],
  toggleLoading: ['isLoading'],
}, {} );

const collapseNav = ( state, { isNavCollapsed } ) => ( { ...state, navCollapsed: isNavCollapsed } );
const updateWindowWith = ( state, { width } ) => ( { ...state, width } );
const switchLanguage = ( state, { locale } ) => ( {
  ...state,
  locale,
  isDirectionRTL: rltLocale.includes( locale.locale ),
} );
const locationChange = state => ( { ...state, navCollapsed: false } );
const toggleAlert = ( state, { msg, category } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, type: category || 'error' },
} );
const toggleLoading = ( state, { isLoading } ) => ( { ...state, isLoading } );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.TOGGLE_COLLAPSED_NAV]: collapseNav,
  [Types.UPDATE_WINDOW_WIDTH]: updateWindowWith,
  [Types.SWITCH_LANGUAGE]: switchLanguage,
  [Types.TOGGLE_ALERT]: toggleAlert,
  [Types.TOGGLE_LOADING]: toggleLoading,
  '@@router/LOCATION_CHANGE': locationChange,
} );

export default Creators;
