import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Table from 'components/Custom/Table/AsyncTable/';
import TableActions from 'components/Custom/Table/Actions';
import TableHeader from 'components/Custom/Table/Header/';
import Confirmation from 'components/Custom/ConfirmationModal';
import CardBox from 'components/CardBox/';
import GroupServices from 'services/Groups';
import SettingsActions from 'store/reducers/Settings';
import IntlMessage from 'util/IntlMessages';
import SearchParams from 'util/SearchParams';

class Dashboard extends React.Component {
  state = {
    data: [],
    alertModal: false,
    criteria: null,
  };

  toDelete = {};

  getData = async ( params, loading = true ) => {
    const { isLoading, toggleLoading } = this.props;
    const { criteria } = this.state;
    if ( loading && !isLoading ) toggleLoading( true );

    params = SearchParams.getSearchParams( { criteria }, params );
    this.params = _.cloneDeep( params );

    const response = await GroupServices.getAll( params );
    toggleLoading( false );

    if ( response.ok ) {
      this.setState( {
        data: response.data.data || [],
        pages: Math.ceil( response.data.total / params.limit ),
        total: response.data.total,
      } );
    }
  };

  filterData = ( val ) => {
    if ( val ) {
      this.setState( { criteria: val }, () => this.getData( this.params, false ) );
    } else {
      this.setState( { criteria: null }, () => this.getData( this.params, false ) );
    }
  };

  toggleAlertModal = ( entity ) => {
    if ( entity ) this.toDelete = entity;
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  deleteEntity = async () => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;
    if ( !isLoading ) toggleLoading( true );

    const response = await GroupServices.deleteGroup( this.toDelete.id );
    toggleLoading( false );
    if ( response.ok ) {
      toggleAlert( response.errors, 'success' );
      this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
      this.getData( this.params );
    } else toggleAlert( response.errors );
  };

  render() {
    const { alertModal, criteria, ...rest } = this.state;

    return (
      <div>
        <CardBox
          heading={(
            <TableHeader
              title="groups"
              criteria={criteria}
              onFilter={this.filterData}
              linkNew="/groups/new"
            />
          )}
          styleName="col-12 p-3"
        >
          <Table
            onFetchData={this.getData}
            rowsText="groups"
            {...rest}
            columns={[
              {
                Header: <IntlMessage id="name" />,
                accessor: 'name',
                className: 'text-left',
                headerClassName: 'text-left',
              },
              {
                accessor: 'id',
                sortable: false,
                maxWidth: 100,
                className: 'justify-content-center',
                Cell: props => (
                  <div>
                    <TableActions
                      editLink
                      onEdit={`/groups/edit/${props.value}`}
                      onDelete={() => this.toggleAlertModal( props.original )}
                    />
                  </div>
                ),
              },
            ]}
          />
        </CardBox>

        <Confirmation
          open={alertModal}
          translateValues={{ name: this.toDelete.name, type: 'Group' }}
          onClose={this.toggleAlertModal}
          onSubmit={this.deleteEntity}
        />
      </div>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Dashboard );
