import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import IntlMessage from 'util/IntlMessages';

const getDisplayString = ( sub ) => {
  return <IntlMessage id={sub}/>;
};

const getUrlString = ( path, sub, index ) => {
  if( index === 0 ) {
    return `/${sub}`;
  } else {
    return '/' + path.split( sub )[0] + sub;
  }
};

const ContainerHeader = ( { match } ) => {
  const path = match.path.substr( 1 );
  const subPath = path.split( '/' );
  const subPathWithoutParams = subPath.filter( ( sub ) => {return sub.charAt( 0 ) !== ":"} );

  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
      <Breadcrumb className="mb-0" tag="nav">
        <BreadcrumbItem active={false} tag={"a"} href={'/'}><IntlMessage id="home"/></BreadcrumbItem>
        {subPathWithoutParams.map( ( sub, index ) => {
            return <BreadcrumbItem active={subPathWithoutParams.length === index + 1}
                                   tag={subPathWithoutParams.length === index + 1 ? "span" : "a"} key={index}
                                   href={getUrlString( path, sub, index )}>{getDisplayString( sub )}</BreadcrumbItem>
          }
        )}
      </Breadcrumb>
    </div>
  )
};

export default withRouter( ContainerHeader );

