import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import _ from 'lodash';

import { Select } from 'components/Custom/FormElements/';
import { AdvancedSearch } from 'components/Custom/Panels/';
import Table from 'components/Custom/Table/AsyncTable/';
import TableActions from 'components/Custom/Table/Actions/';
import TableHeader from 'components/Custom/Table/Header/';
import Confirmation from 'components/Custom/ConfirmationModal';
import CardBox from 'components/CardBox/';
import CsLovMeterServices from 'services/LovMeter';
import SettingsActions from 'store/reducers/Settings';
import SearchParams from 'util/SearchParams';
import IntlMessage from 'util/IntlMessages';
import Detail from './components/Detail';

class Dashboard extends React.Component {
  state = {
    data: [],
    search: {},
    criteria: null,
    alertModal: false,
    detailModal: false,
  };

  entitySelected = {};

  getData = async ( params, loading = true ) => {
    const { isLoading, toggleLoading } = this.props;
    const { search, criteria } = this.state;
    if ( loading && !isLoading ) toggleLoading( true );

    params = SearchParams.getSearchParams( { ...search, criteria }, params );
    this.params = _.cloneDeep( params );

    const response = await CsLovMeterServices.getAll( params );
    toggleLoading( false );
    if ( response.ok ) {
      this.setState( {
        data: response.data.data || [],
        pages: Math.ceil( response.data.total / params.limit ),
        total: response.data.total,
      } );
    }
  };

  filterData = ( val ) => {
    if ( val ) {
      this.setState( { criteria: val }, () => this.getData( this.params, false ) );
    } else {
      this.setState( { criteria: null }, () => this.getData( this.params, false ) );
    }
  };

  clearSearch = () => {
    this.setState( { search: { lovInUse: null } }, () => this.getData( this.params ) );
  };

  toggleModal = async ( entity ) => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;

    if ( entity.id ) {
      if ( !isLoading ) toggleLoading( true );
      const response = await CsLovMeterServices.getCsLovMeter( entity.id );
      toggleLoading( false );

      if ( response.ok ) {
        this.entitySelected = response.data;
        this.setState( prevState => ( { detailModal: !prevState.detailModal } ) );
      } else toggleAlert( response.errors );
    } else {
      this.setState( prevState => ( { detailModal: !prevState.detailModal } ) );
    }
  };

  toggleAlertModal = ( entity ) => {
    if ( entity ) this.entitySelected = entity;
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  deleteEntity = async () => {
    const { isLoading, toggleLoading, toggleAlert } = this.props;
    if ( !isLoading ) toggleLoading( true );
    const response = await CsLovMeterServices.deleteCsLovMeter( this.entitySelected.id );
    toggleLoading( false );
    if ( response.ok ) {
      toggleAlert( response.errors, 'success' );
      this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
      this.getData( this.params );
    } else toggleAlert( response.errors );
  };

  render() {
    const { search, alertModal, detailModal, criteria } = this.state;
    return (
      <div>
        <CardBox
          heading={(
            <TableHeader
              title="listLovsMeter"
              criteria={criteria}
              onFilter={this.filterData}
              linkNew="/lov-meter/new"
            />
          )}
          styleName="col-12 p-3"
        >
          <div>
            <AdvancedSearch>
              <div className="row">
                <div className="col-sm-3">
                  <Select
                    value={search.lovInUse}
                    onChange={val => this.setState( {
                      search: {
                        ...search,
                        lovInUse: val,
                      },
                    } )}
                    placeholder="active"
                    labelKey="name"
                    options={[
                      { id: 1, name: 'active' },
                      { id: '0', name: 'inactive' },
                    ]}
                  />
                </div>
                <div className="col-sm-3 d-flex mb-4">
                  <Button
                    variant="contained"
                    className="jr-btn"
                    color="primary"
                    onClick={() => this.getData( this.params )}
                  >
                    <i className="fa fa-search" />
                  </Button>
                  <Button
                    variant="contained"
                    className="jr-btn"
                    color="primary"
                    onClick={this.clearSearch}
                  >
                    <i className="fa fa-trash-alt" />
                  </Button>
                </div>
              </div>
            </AdvancedSearch>

            <Table
              onFetchData={this.getData}
              rowsText="lovMeter"
              {...this.state}
              columns={[
                { Header: <IntlMessage id="ID" />, accessor: 'id' },
                {
                  Header: <IntlMessage id="meterNumber" />,
                  accessor: 'meterSerialNumer',
                  Cell: props => (
                    <button
                      className="m-0 p-0 bg-transparent border-0 text-primary"
                      type="button"
                      onClick={() => this.toggleModal( props.original )}
                    >
                      {props.value}
                    </button>
                  ),
                },
                { Header: <IntlMessage id="paymentMode" />, accessor: 'paymentMode' },
                {
                  Header: <IntlMessage id="customerName" />,
                  accessor: 'customerFirstName',
                  Cell: props => (
                    <div>
                      {props.original.customerFirstName}
                      {' '}
                      {props.original.customerSurname}
                    </div>
                  ),
                },
                {
                  Header: <IntlMessage id="active" />,
                  accessor: 'lovInUse',
                  Cell: props => (
                    <div className="m-auto">
                      {props.value ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
                    </div>
                  ),
                },
                {
                  maxWidth: 100,
                  sortable: false,
                  className: 'justify-content-center',
                  Cell: props => (
                    <TableActions
                      onEdit={`/lov-meter/edit/${props.original.id}`}
                      editLink
                      onDelete={() => this.toggleAlertModal( props.original )}
                    />
                  ),
                },
              ]}
            />
          </div>
        </CardBox>
        <Detail
          open={detailModal}
          onClose={this.toggleModal}
          data={this.entitySelected}
        />
        <Confirmation
          open={alertModal}
          translateValues={{ name: this.entitySelected ? this.entitySelected.meterSerialNumer : '', type: 'lovMeter' }}
          onClose={this.toggleAlertModal}
          onSubmit={this.deleteEntity}
        />
      </div>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Dashboard );
