import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import Edit from './Edit';

const LovMeters = ( { match } ) => (
  <Switch>
    <Route path={`${match.path}/edit/:id`} component={Edit} />
    <Route path={`${match.path}/new`} component={Edit} />
    <Route path={`${match.path}`} component={Dashboard} />
  </Switch>
);

export default LovMeters;
