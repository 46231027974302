import React from 'react';
import { Form } from 'react-form';
import { connect } from 'react-redux';

import ContainerHeader from 'components/ContainerHeader';
import CsLovMeterServices from 'services/LovMeter';
import { TextField, Buttons } from 'components/Custom/FormElements/';
import { required, email } from 'config/InputErrors';
import CardBox from 'components/CardBox/';
import SettingsActions from 'store/reducers/Settings';

class Edit extends React.Component {
  state = { data: {} };
  entityId = null;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;

    if ( this.entityId ) this.getData();
  }

  getData = async () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    const response = await CsLovMeterServices.getCsLovMeter( this.entityId );
    toggleLoading( false );
    if ( response.ok ) this.setState( { data: response.data } );
  };

  submitForm = async ( data ) => {
    const { history, toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    const response = this.entityId
      ? await CsLovMeterServices.updateCsLovMeter( this.entityId, data )
      : await CsLovMeterServices.createCsLovMeter( data );

    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );

    return history.push( '/lov-meter' );
  };

  render() {
    const { data } = this.state;
    const { match } = this.props;
    return (
      <div>
        <ContainerHeader match={match} />

        <CardBox
          heading={this.entityId ? 'editLovMeter' : 'createLovMeter'}
          styleName="col-12 p-3"
        >
          <Form
            values={data}
            onSubmit={this.submitForm}
            validate={values => ( {
              meterSerialNumer: required( values.meterSerialNumer ),
              customerFirstName: required( values.customerFirstName ),
              paymentMode: required( values.paymentMode ),
              email: values.email ? email( values.email ) : null,
            } )}
          >
            {( { submitForm } ) => (
              <form onSubmit={submitForm} className="row">
                <div className="col-sm-6">
                  <TextField field="meterSerialNumer" label="meterNumber" />
                </div>

                <div className="col-sm-6">
                  <TextField field="meterManufacturer" label="meterManufacturer" />
                </div>

                <div className="col-sm-6">
                  <TextField field="customerFirstName" label="customerFirstName" />
                </div>

                <div className="col-sm-6">
                  <TextField field="customerSurname" label="customerSurname" />
                </div>

                <div className="col-sm-6">
                  <TextField field="phone" label="phone" />
                </div>

                <div className="col-sm-6">
                  <TextField field="email" label="email" />
                </div>

                <div className="col-sm-6">
                  <TextField field="paymentMode" label="paymentMode" />
                </div>

                <div className="col-sm-6">
                  <TextField field="poleNumber" label="poleNumber" />
                </div>

                <div className="col-sm-6">
                  <TextField field="supplyPhase" label="supplyPhase" />
                </div>

                <div className="col-sm-6">
                  <TextField field="supplyVoltage" label="supplyVoltage" />
                </div>

                <div className="col-md-12 text-right">
                  <Buttons cancelTo="/lov-meter" />
                </div>
              </form>
            )}
          </Form>
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( Edit );
