import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SettingsActions from 'store/reducers/Settings';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'store/constants/ActionTypes';
import SidenavContent from './SidenavContent';

class SideNav extends React.PureComponent {
  onToggleCollapsedNav = ( e ) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav( val );
  };

  componentDidMount() {
    window.addEventListener( 'resize', () => {
      this.props.updateWindowWidth( window.innerWidth );
    } );
  }

  render() {
    const { navCollapsed, drawerType, width, navigationStyle } = this.props;
    let drawerStyle = drawerType.includes( FIXED_DRAWER ) ? 'd-xl-flex' : drawerType.includes( COLLAPSED_DRAWER ) ? '' : 'd-flex';
    let type = 'persistent';
    if ( drawerType.includes( COLLAPSED_DRAWER ) || ( drawerType.includes( FIXED_DRAWER ) && width < 1200 ) ) {
      type = 'temporary';
    }

    if ( navigationStyle === HORIZONTAL_NAVIGATION ) {
      drawerStyle = '';
      type = 'temporary';
    }
    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes( 'temporary' ) ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{ paper: 'side-nav' }}
        >
          <SidenavContent />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ( { settings } ) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  return { navCollapsed, drawerType, width, navigationStyle };
};

export default withRouter( connect( mapStateToProps, {
  toggleCollapsedNav:SettingsActions.toggleCollapsedNav,
  updateWindowWidth: SettingsActions.updateWindowWidth
} )( SideNav ) );
