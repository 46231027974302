import React, { Component } from 'react';
import { connect } from 'react-redux';
import async from 'async';
import { Form } from 'react-form';
import classname from 'classnames';

import ContainerHeader from 'components/ContainerHeader';
import { TextField, Select, Buttons } from 'components/Custom/FormElements';
import { required, email } from 'config/InputErrors';
import CardBox from 'components/CardBox/';
import GroupsServices from 'services/Groups';
import UserServices from 'services/User';
import SettingsActions from 'store/reducers/Settings';

class Edit extends Component {
  state = { data: {}, groups: [] };
  entityId = null;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params ? match.params.id : null;
    this.getData();
  }

  getData = () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    async.parallel( {
      groups: this.getGroups,
      user: this.getUser,
    }, ( error, result ) => {
      toggleLoading( false );

      if ( error ) return toggleAlert( error );
      this.setState( {
        data: result.user || {},
        groups: result.groups.data || [],
      } );
    } );
  };

  getGroups = async ( cb ) => {
    const response = await GroupsServices.getAll( { limit: -1 } );
    return cb( response.errors, response.data );
  };

  getUser = async ( cb ) => {
    if ( !this.entityId ) return cb( null, {} );
    const response = await UserServices.getUser( this.entityId );
    return cb( response.errors, response.data );
  };

  submitForm = async ( values ) => {
    const { toggleLoading, toggleAlert, history } = this.props;
    toggleLoading( true );

    const data = { ...values, userGroup: values.user_group };

    const response = this.entityId ? await UserServices.updateUser( this.entityId, data )
      : await UserServices.createUser( data );
    toggleLoading( false );
    if ( response.ok ) {
      if ( this.entityId ) toggleAlert( 'dataSaved' );
      return history.push( '/users' );
    }
    toggleAlert( response.errors );
  };

  render() {
    const { data, groups } = this.state;
    return (
      <div>
        <ContainerHeader />

        <CardBox
          heading={this.entityId ? 'editUser' : 'createUser'}
          headingTranslateValues={{ name: data.name }}
        >
          <div>
            <Form
              values={{ ...data, user_group: data.user_group ? data.user_group.id : undefined }}
              onSubmit={this.submitForm}
              validate={values => ( {
                name: required( values.name ),
                lastname: required( values.lastname ),
                username: required( values.username ),
                password: ( !this.entityId ) ? required( values.password ) : null,
                email: required( values.email ) || email( values.email ),
                roles: required( values.roles ),
              } )}
            >
              {( { submitForm } ) => (
                <form onSubmit={submitForm} className="row">
                  <div className="col-sm-6">
                    <TextField field="name" label="name" />
                  </div>

                  <div className="col-sm-6">
                    <TextField field="lastname" label="lastName" />
                  </div>

                  <div className="col-sm-6">
                    <TextField field="username" label="username" />
                  </div>

                  <div className="col-sm-6">
                    <TextField type="email" field="email" label="email" />
                  </div>

                  {!this.entityId
                    && (
                    <div className="col-sm-6">
                      <TextField type="password" field="password" label="password" />
                    </div>
                    )
                  }

                  <div className="col-sm-6">
                    <Select
                      field="roles"
                      label="roles"
                      isMulti
                      translateOptions={false}
                      options={[
                        { id: 'ROLE_SUPERVISOR', name: 'ROLE_SUPERVISOR' },
                        { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
                        { id: 'ROLE_API', name: 'ROLE_API' },
                      ]}
                    />
                  </div>

                  <div className={classname( 'col-sm-6', { 'offset-sm-6': !this.entityId } )}>
                    <Select
                      label="group"
                      field="user_group"
                      options={groups || []}
                    />
                  </div>

                  <div className="col-md-12 text-right">
                    <Buttons cancelTo="/users" />
                  </div>
                </form>
              )}
            </Form>
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( Edit );
