import React, { Component } from 'react';
import { connect } from 'react-redux';

import TableHeader from 'components/Custom/Table/Header/';
import Table from 'components/Custom/Table/Custom/';
import TableActions from 'components/Custom/Table/Actions/';
import CheckColumn from 'components/Custom/Table/CheckColumn/';
import IntlMessage from 'util/IntlMessages';
import ConfirmationModal from 'components/Custom/ConfirmationModal';
import EntityChildrenService from 'services/EntityChildren';
import SettingsActions from 'store/reducers/Settings';
import EntityChildrenEdit from '../EntityChildrenEdit';

class EntityChildrenList extends Component {
  state = {
    detailModal: false,
    alertModal: false,
  };
  entitySelected = {};

  toggleEditModal = () => {
    this.setState( prevState => ( { detailModal: !prevState.detailModal } ) );
  };

  toggleAlertModal = ( entity ) => {
    if ( entity ) this.entitySelected = entity;
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  deleteEntity = async () => {
    const { isLoading, toggleLoading, toggleAlert, getData } = this.props;
    if ( !isLoading ) toggleLoading( true );

    if ( this.entitySelected.id ) {
      const response = await EntityChildrenService.deleteChildren( this.entitySelected.id );
      toggleLoading( false );
      if ( response.ok ) {
        this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
        getData();
      } else toggleAlert( response.errors );
    }
  };

  createDependentSubmitForm = async ( values ) => {
    const { toggleLoading, toggleAlert, getData, entityId } = this.props;
    toggleLoading( true );

    const response = await EntityChildrenService.createEntityChildren( {
      csChildEntityObject: values.dependent,
      csEntityObject: entityId,
    } );

    toggleLoading( false );
    if ( !response.ok ) return toggleAlert( response.errors );

    this.toggleEditModal();
    getData();
  };

  render() {
    const { criteria, filterData, data, entityId } = this.props;
    const { alertModal, detailModal } = this.state;

    return (
      <div className="border-top mt-5">
        <div className="my-4">
          <TableHeader
            styleTitle
            title="dependents"
            criteria={criteria}
            onFilter={filterData}
            newAction={this.toggleEditModal}
          />
        </div>

        <Table
          rowsText="dependents"
          data={data}
          columns={[
            { Header: <IntlMessage id="name" />, accessor: 'csChildEntityObject.objectName' },
            { Header: <IntlMessage id="type" />, accessor: 'csChildEntityObject.objecType' },
            { Header: <IntlMessage id="topology" />, accessor: 'csChildEntityObject.objecTopology' },
            { Header: <IntlMessage id="igeaId" />, accessor: 'csChildEntityObject.codExt' },
            {
              Header: <IntlMessage id="active" />,
              accessor: 'csChildEntityObject.objecActive',
              className: 'justify-content-center',
              Cell: CheckColumn,
            },
            {
              Header: <IntlMessage id="firstLevel" />,
              accessor: 'csChildEntityObject.firstLevel',
              className: 'justify-content-center',
              Cell: CheckColumn,
            },
            {
              maxWidth: 80,
              className: 'justify-content-center',
              sortable: false,
              Cell: props => (
                <TableActions
                  onDelete={() => this.toggleAlertModal( props.original )}
                />
              ),
            },
          ]}
        />

        <ConfirmationModal
          open={alertModal}
          translateValues={{
            name: this.entitySelected && this.entitySelected.csChildEntityObject ? this.entitySelected.csChildEntityObject.objectName : '',
            type: 'dependent',
          }}
          onClose={() => this.toggleAlertModal()}
          onSubmit={this.deleteEntity}
        />

        <EntityChildrenEdit
          open={detailModal}
          entityId={entityId}
          onClose={this.toggleEditModal}
          onSubmit={this.createDependentSubmitForm}
          data={data}
        />
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( EntityChildrenList );
