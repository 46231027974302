import React from 'react';
import ReactDOM from 'react-dom';
import MainApp from './MainApp';

const rootEl = document.getElementById( 'app-site' );

const render = () => {
  ReactDOM.render(
    <MainApp />,
    rootEl,
  );
};

if ( module.hot ) {
  module.hot.accept( './MainApp', () => {
    render(
      <MainApp />,
      rootEl,
    );
  } );
}

render();
