import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
} from '@material-ui/core';
import _ from 'lodash';

import WorksServices from 'services/Works';
import { Select, Buttons } from 'components/Custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import { oneRequired } from 'config/InputErrors';
import IntlMessage from 'util/IntlMessages';


class Edit extends Component {
  userOptions = [];

  componentDidUpdate( props ) {
    this.getUserOptions( props );
  }

  getUserOptions = ( props ) => {
    const { users } = this.props;
    if ( !_.isEqual( users, props.users ) ) {
      this.userOptions = users ? users.map( user => ( {
        id: user.id,
        name: `${user.name || ''} ${user.lastname || ''}`,
      } ) ) : [];
    }
  };

  submitForm = async ( values ) => {
    const { isLoading, toggleLoading, toggleAlert, data, reloadData, onClose } = this.props;
    if ( !isLoading ) toggleLoading( true );

    const fixedValues = {
      workGroup: values.workGroup || '',
      responsible: values.responsible || '',
    };

    const response = await WorksServices.updateWork( data.id, fixedValues );
    toggleLoading( false );
    if ( response.ok ) {
      toggleAlert( 'dataSaved' );
      onClose();
      reloadData();
    } else toggleAlert( response.errors );
  };

  render() {
    const { open, data, onClose, groups } = this.props;
    return (
      <Dialog onClose={onClose} TransitionComponent={Slide} open={open} fullWidth>
        <DialogTitle className="bg-primary mb-3 px-3 py-2 text-white">
          <IntlMessage id="work" />
          {`: ${data.work_name || ''}`}
        </DialogTitle>
        <Form
          onSubmit={this.submitForm}
          onChange={( formState, formApi ) => formApi.setAllTouched()}
          values={{ ...data, workGroup: data.work_group }}
          validate={values => ( {
            responsible: oneRequired( [values.responsible, values.workGroup] ),
            workGroup: oneRequired( [values.responsible, values.workGroup] ),
          } )}
        >
          {( { submitForm } ) => (
            <form onSubmit={submitForm}>
              <DialogContent>
                <div className="row">
                  <div className="col-sm-6">
                    <Select
                      label="user"
                      field="responsible"
                      options={this.userOptions}
                    />
                  </div>

                  <div className="col-sm-6">
                    <Select
                      label="group"
                      field="workGroup"
                      options={groups.data || []}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="col-md-12 text-right">
                  <Buttons onClickCancel={onClose} />
                </div>
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    );
  }
}

const mapStateToProps = ( { settings } ) => ( { isLoading: settings.isLoading } );

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Edit );
