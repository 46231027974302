import api from '../config/Api';

const baseUrl = 'v1.0/csworks';

export default {
  getWorks( params ) {
    return api.get( `${baseUrl}/works/timeline`, params );
  },

  getWork( id ) {
    return api.get( `${baseUrl}/${id}/objectdependecy` );
  },

  deleteWork( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },

  updateWork( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },
};
