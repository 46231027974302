import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';
import moment from 'moment';

import ContainerHeader from 'components/ContainerHeader';
import Confirmation from 'components/Custom/ConfirmationModal';
import SearchParams from 'util/SearchParams';
import CardBox from 'components/CardBox/';
import WorkServices from 'services/Works';
import UserServices from 'services/User';
import GroupsServices from 'services/Groups';
import SettingsActions from 'store/reducers/Settings';
import Edit from './components/Edit';
import WorkHeader from './components/WorkHeader';
import WorkDetail from './components/WorkDetail';
import WorkTimeline from './components/WorkTimeline';

class Detail extends Component {
  state = {
    data: {},
    timelineData: {},
    activeIndex: -1,
    selectedItem: null,
    alertModal: false,
    editModal: false,
  };
  groups = {};
  users = {};
  currentGroup = null;
  responsible = null;
  dataLoaded = false;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;
    this.getData();
  }

  getData = ( reloadTimeline = true ) => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    async.parallel( {
      data: ( cb ) => {
        if ( !this.entityId ) return cb();
        async.waterfall( [
          this.getWork,
          this.getTimelineData.bind( this, reloadTimeline ),
        ], cb );
      },
      groups: this.getGroups,
      users: this.getUsers,
    }, ( error, result ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      this.dataLoaded = true;
      const { work, timelineData } = result.data ? result.data : {};

      this.groups = result.groups || {};
      this.users = result.users || {};
      this.currentGroup = this.groups.data && work
        ? _.find( this.groups.data, { id: work.work_group } ) : null;
      this.responsible = work ? _.find( this.users, { id: work.responsible } ) : null;

      this.setState( { data: work, timelineData } );
    } );
  };

  getWork = async ( cb ) => {
    const response = await WorkServices.getWork( this.entityId );
    return cb( response.errors, response.data );
  };

  getTimelineData = async ( reloadTimeline, work, cb ) => {
    const { timelineData } = this.state;
    if ( !reloadTimeline ) return cb( null, { work, timelineData } );

    const status = work.work_status === 'APPROVED'
      ? 'APPROVED,GATHERED,REJECTED,INVALIDATED' : 'GATHERED,REJECTED,INVALIDATED';
    const params = SearchParams.getSearchParams( { mStatus: status, refId: work.id },
      { 'order_by[updateDate]': 'ASC' } );

    const response = await WorkServices.getWorks( params );
    if ( !response.ok ) return cb( response.errors );

    return cb( null, {
      work,
      timelineData: {
        total: response.data.total,
        data: response.data.data.map( item => ( {
          ...item,
          date: moment( new Date( item.update_date ) ).format( 'DD/MM/YYYY hh:mm A' ),
        } ) ),
      },
    } );
  };

  getGroups = async ( cb ) => {
    if ( this.dataLoaded ) return cb( null, this.groups );
    const response = await GroupsServices.getAll( { limit: -1 } );
    return cb( response.errors, response.data );
  };

  getUsers = async ( cb ) => {
    if ( this.dataLoaded ) return cb( null, this.users );
    const response = await UserServices.getUsers( { limit: -1 } );
    return cb( response.errors, response.data );
  };

  handleTimelineItemClick = async ( item, index ) => {
    const { activeIndex, selectedItem } = this.state;
    const { toggleLoading, toggleAlert } = this.props;

    if ( index === activeIndex ) {
      this.setState( { activeIndex: -1 } );
    } else if ( !selectedItem || item.id !== selectedItem.id ) {
      toggleLoading( true );

      const response = await WorkServices.getWork( item.id );
      toggleLoading( false );

      if ( response.ok ) this.setState( { selectedItem: response.data, activeIndex: index } );
      else toggleAlert( response.errors );
    } else this.setState( { activeIndex: index } );
  };

  deleteEntity = async () => {
    const { toggleLoading, toggleAlert } = this.props;
    const { selectedItem } = this.state;

    if ( selectedItem ) {
      toggleLoading( true );
      const response = await WorkServices.deleteWork( selectedItem.id );
      toggleLoading( false );

      if ( response.ok ) {
        this.setState( { activeIndex: -1, selectedItem: null, alertModal: false },
          () => this.getData() );
      } else toggleAlert( response.errors );
    }
  };

  toggleEditModal = () => {
    this.setState( prevState => ( { editModal: !prevState.editModal } ) );
  };

  toggleAlertModal = () => {
    this.setState( prevState => ( { alertModal: !prevState.alertModal } ) );
  };

  render() {
    const { data, editModal, activeIndex, selectedItem, alertModal, timelineData } = this.state;

    return (
      <div>
        <ContainerHeader />
        <CardBox
          heading={<WorkHeader data={data} onEdit={this.toggleEditModal} />}
        >
          <div>
            <WorkDetail data={data} responsible={this.responsible} group={this.currentGroup} />

            {!!timelineData.data && !!timelineData.data.length
            && (
              <div className="row">
                <WorkTimeline
                  data={timelineData.data}
                  selectedItem={selectedItem || {}}
                  activeIndex={activeIndex}
                  onClickItem={this.handleTimelineItemClick}
                  onDelete={this.toggleAlertModal}
                />
              </div>
            )}

            <Confirmation
              open={alertModal}
              translateValues={{ name: selectedItem ? selectedItem.work_name : '', type: 'work' }}
              onClose={this.toggleAlertModal}
              onSubmit={this.deleteEntity}
            />
          </div>
        </CardBox>

        <Edit
          onClose={this.toggleEditModal}
          open={editModal}
          data={data}
          groups={this.groups}
          users={this.users}
          reloadData={() => this.getData( false )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect(
  null,
  mapDispatchToProps,
)( Detail );
