import React from 'react';

import IntlMessage from 'util/IntlMessages';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block"><IntlMessage id="footerCopyRight" values={{year: 2019}} /></span>
      </footer>
    );
  }
;

export default Footer;
