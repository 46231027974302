import api from '../config/Api';

const baseUrl = 'v1.0/cslovs';

export default {
  getLovs( params ) {
    return api.get( baseUrl, params );
  },

  getLov( id ) {
    return api.get( `${baseUrl}/${id}` );
  },

  createLov( data ) {
    return api.post( baseUrl, data );
  },

  updateLov( id, data ) {
    return api.put( `${baseUrl}/${id}`, data );
  },

  deleteLov( id ) {
    return api.delete( `${baseUrl}/${id}` );
  },
};
